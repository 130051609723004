import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SuppliersApi from '../../api/suppliersApi';
import { Button, TextField, withStyles } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { suppliersInfoStyles as styles } from './styles';
import { Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import StockApi from '../../api/stockApi';

function Supplier({ classes }) {
    const url = window.location.pathname;
    const splitUrl = url.split('/');
    const supplierId = splitUrl[2] || '';
    const [supplierInfo, setSupplierInfo] = useState({});
    const [title, setTitle] = useState('');
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await SuppliersApi.searchById(supplierId).then((res) => {
                setSupplierInfo({
                    name: res.data.name || '',
                    code: res.data.code || '',
                    description: res.data.description || '',
                    address: res.data.address || '',
                    address2: res.data.address2 || '',
                    city: res.data.city || '',
                    county: res.data.county || '',
                    postCode: res.data.postCode || '',
                    telephone: res.data.telephone || '',
                    email: res.data.email || '',
                    contactName: res.data.contactName || '',
                    brands: res.data.brands || []
                });

                setTitle(res.data.name || '');
            });

            await StockApi.getAllBrands().then((res) => setBrands(res));
        };
        fetchData();
        //eslint-disable-next-line
    }, []);

    const handleChange = (field, newValue) => {
        setSupplierInfo({
            ...supplierInfo,
            [field]: newValue
        });
    };

    const getDefaultBrands = () => {
        if (supplierInfo.brands.length > 0)
            /*eslint-disable-next-line */
            return supplierInfo.brands.map((brand) => {
                const index = brands.findIndex((item) => brand === item.id);
                if (index !== -1) return brands[index];
            });

        return [];
    };

    const handleSave = () => {
        const getBrandsIds = () => {
            return {
                ...supplierInfo,
                brands: supplierInfo.brands.map((brand) => brand.id)
            };
        };

        const saveChanges = async () => {
            const newData = supplierInfo.brands.length > 0 ? getBrandsIds() : supplierInfo;

            const response = await SuppliersApi.update(supplierId, newData);

            if (response.status === 200) toastr.success('Success!', 'The item was updated successfully!');
            else toastr.error('Error!', 'Something went wrong...');
        };
        saveChanges();
    };

    return (
        <div className={classes.body}>
            {Object.keys(supplierInfo).length > 0 && brands.length > 0 && (
                <>
                    <Typography className={classes.title}>Supplier {title}</Typography>
                    <form className={classes.form}>
                        <TextField
                            label={'Name'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.name || ''}
                            onChange={(e) => handleChange('name', e.target.value)}
                            required
                        />
                        <TextField
                            label={'Code'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.code || ''}
                            onChange={(e) => handleChange('code', e.target.value)}
                            required
                        />
                        <TextField
                            label={'Description'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.description || ''}
                            onChange={(e) => handleChange('description', e.target.value)}
                        />
                        <TextField
                            label={'Address'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.address || ''}
                            onChange={(e) => handleChange('address', e.target.value)}
                            required
                        />
                        <TextField
                            label={'Address2'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.address2 || ''}
                            onChange={(e) => handleChange('address2', e.target.value)}
                        />
                        <TextField
                            label={'City'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.city || ''}
                            onChange={(e) => handleChange('city', e.target.value)}
                        />
                        <TextField
                            label={'County'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.county || ''}
                            onChange={(e) => handleChange('county', e.target.value)}
                        />
                        <TextField
                            label={'Post Code'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.postCode || ''}
                            onChange={(e) => handleChange('postCode', e.target.value)}
                        />
                        <TextField
                            label={'Telephone'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.telephone || ''}
                            onChange={(e) => handleChange('telephone', e.target.value)}
                            required
                        />
                        <TextField
                            label={'E-mail'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.email || ''}
                            onChange={(e) => handleChange('email', e.target.value)}
                            required
                        />
                        <TextField
                            label={'Contact Name'}
                            variant="outlined"
                            className={classes.formItem}
                            defaultValue={supplierInfo.contactName || ''}
                            onChange={(e) => handleChange('contactName', e.target.value)}
                            required
                        />
                        <Autocomplete
                            size="small"
                            fullWidth
                            autoComplete
                            autoHighlight
                            multiple
                            options={brands}
                            className={classes.formItem}
                            defaultValue={getDefaultBrands()}
                            getOptionLabel={(opt) => opt && opt.name}
                            onChange={(e, value) => handleChange('brands', value)}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth variant="outlined" placeholder="Brands" />
                            )}
                        />
                        <div>
                            <Button
                                variant="outlined"
                                className={classes.formItem}
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSave();
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.formItem}
                                type="submit"
                                component={Link}
                                to="/suppliers"
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}

Supplier.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(Supplier);
