import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar, Tabs, Tab, makeStyles } from '@material-ui/core';

import { headerStyles } from '../../collums-constants/styles/stylesheets/headerStyles';

import ClinicSelector from '../../collums-components/components/common/ClinicSelector';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

const mainTabsArray = [
    { value: 'stock', label: 'Stock' },
    { value: 'stock-take', label: 'Stock take' }
    // { value: 'orders', label: 'Orders' },
    // { value: 'suppliers', label: 'Suppliers' }
];

//It is a function just to assure there will be no refactoring if subtabs are created
const tabToNewWindow = (tab) => {
    return `/${tab}`;
};

const Header = () => {
    const location = useLocation();
    const history = useHistory();

    const getCurrentClinic = () => {
        return localStorage.getItem(CURRENT_CLINIC);
    };

    const headerClasses = makeStyles(headerStyles)();

    const getSelectedTab = (level) => location.pathname.split('/')[level];

    const renderTab = (tabObject, className, index) => {
        const navigate = tabToNewWindow(tabObject.value);
        return (
            <Tab
                key={index}
                className={className}
                value={tabObject.value}
                label={
                    <a href={navigate} onClick={(e) => e.preventDefault()}>
                        {tabObject.label}
                    </a>
                }
            />
        );
    };

    if (!getSelectedTab(1)) {
        return null;
    }

    const handleOnChangeClinic = (clinic) => {
        localStorage.setItem(CURRENT_CLINIC, clinic.id);
        window.location.reload();
    };

    return (
        <>
            <AppBar position="static" color="secondary" classes={{ root: headerClasses.noShadow }}>
                <Tabs
                    variant="fullWidth"
                    value={getSelectedTab(1)}
                    className={headerClasses.tabsContainer}
                    onChange={(e, value) => {
                        history.push(`/${value}`);
                    }}
                >
                    {mainTabsArray.map((tab, index) => renderTab(tab, headerClasses.mainTab, index))}
                </Tabs>
            </AppBar>
            {getSelectedTab(1) !== 'stock' && (
                <div style={{ padding: '15px 35px 0' }}>
                    <ClinicSelector clinicId={getCurrentClinic()} onChange={handleOnChangeClinic} />
                </div>
            )}
        </>
    );
};

export default Header;
