import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { stockTakeAllListStyles as styles } from './styles';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    withStyles
} from '@material-ui/core';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import ActionButton from '../common/ActionButton';
import { STOCK_TAKE } from '../../collums-constants';

const headerTable = [
    { id: 'date', order: 'createdAt', label: 'Date & Time', reordable: true },
    { id: 'code', order: 'code', label: 'Code', reordable: true },
    { id: 'name', order: 'name', label: 'Name', reordable: true },
    { id: 'brand', order: 'brand', label: 'Brand', reordable: true },
    { id: 'category', order: 'category', label: 'Category', reordable: true },
    { id: 'performedBy', order: 'performedBy', label: 'Performed By', reordable: true },
    { id: 'discrepancy', order: 'discrepancy', label: 'Discrepancy', type: 'number', reordable: true },
    { id: 'status', order: 'status', label: 'Status', reordable: true }
];

function StockTakeTable({
    tableData,
    classes,
    setPage,
    page,
    setPageSize,
    pageSize,
    numOfItems,
    numOfBrands,
    numOfCategories,
    sort,
    clinic,
    setSort,
    openModal
}) {
    const history = useHistory();

    const actions = [
        {
            title: 'archive',
            action: openModal
        },
        {
            title: 'delete',
            action: openModal
        }
    ];

    const handleSortFilter = (cellOrder) => {
        setSort({
            direction: sort.direction === 'asc' ? 'desc' : 'asc',
            active: cellOrder
        });
    };

    const renderTableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {headerTable.map((cell) => {
                        return (
                            <TableCell
                                key={cell.id}
                                padding="default"
                                className={`${classes.tableHeader} ${
                                    cell.type === 'number' ? classes.rightAlignCellHeader : ''
                                }`}
                                sortDirection={sort.direction}
                            >
                                {cell.reordable ? (
                                    <TableSortLabel
                                        direction={sort.direction}
                                        active={sort.active === cell.order}
                                        onClick={() => handleSortFilter(cell.order)}
                                    >
                                        <span>{cell.label}</span>
                                    </TableSortLabel>
                                ) : (
                                    <span>{cell.label}</span>
                                )}
                            </TableCell>
                        );
                    })}
                    <TableCell className={classes.tableHeader} style={{ textAlign: 'center' }}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const showStatus = useCallback((row) => {
        if (row.status === STOCK_TAKE.STATUS.COUNT_PAUSED) {
            return 'Paused';
        } else if (row.status === STOCK_TAKE.STATUS.COMPLETE) {
            return 'Completed';
        } else {
            return 'Completed/stock updated';
        }
    }, []);

    return (
        <>
            <Table className={classes.table} size="medium" stickyHeader>
                {renderTableHeader()}
                <TableBody>
                    {tableData &&
                        tableData.map((row) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    className={`${classes.tableRow}`}
                                    style={{ backgroundColor: !row.archived ? 'white' : '#ff9e9e' }}
                                >
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{moment(row.createdAt).format('DD/MM/YY HH:mm')}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{row.code}</span>
                                    </TableCell>
                                    <TableCell
                                        className={`${classes.tableBodyCell}`}
                                        onClick={() => history.push(`/stock-take/${row.id}?clinic=${clinic}`)}
                                    >
                                        <span className={`${classes.tableName}`}>{row.name}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        {numOfBrands === row.brand.length ? (
                                            'All'
                                        ) : (
                                            <span>{row.brand.map((brand) => brand.name).join(', ')}</span>
                                        )}
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        {numOfCategories === row.category.length ? (
                                            'All'
                                        ) : (
                                            <span>{row.category.map((category) => category.name).join(', ')}</span>
                                        )}
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{`${row.performedBy ? row.performedBy.firstName : ''} ${
                                            row.performedBy ? row.performedBy.surname : ''
                                        }`}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell} ${classes.rightAlignCell}`}>
                                        <span>{row.products.length > 0 ? row.discrepancy : '0'}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{showStatus(row)}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`} style={{ textAlign: 'center' }}>
                                        <ActionButton items={actions} data={row} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={numOfItems}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(e, value) => {
                    setPage(value);
                }}
                onChangeRowsPerPage={(e) => {
                    setPageSize(e.target.value);
                }}
                classes={{ root: classes.tablePagination }}
            />
        </>
    );
}

StockTakeTable.propTypes = {
    openModal: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
    numOfBrands: PropTypes.number.isRequired,
    numOfCategories: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    numOfItems: PropTypes.number.isRequired,
    sort: PropTypes.object.isRequired,
    setSort: PropTypes.func.isRequired,
    clinic: PropTypes.string.isRequired
};

export default withStyles(styles)(StockTakeTable);
