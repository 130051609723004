import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import StockView from './components/stock';
import StockTake from './components/stockTake';
import Orders from './components/orders';
import NewOrderForm from './components/orders/NewOrderForm';
import Suppliers from './components/suppliers';
import Supplier from './components/supplier';
import ProductStockTake from './components/productStockTake';

function Routes() {
    return (
        <Switch>
            <Redirect exact path="/" to="/stock" />
            <Route exact path="/stock" component={StockView} />
            <Route exact path="/stock-take" component={StockTake} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/create-order" component={NewOrderForm} />
            <Route exact path="/suppliers" component={Suppliers} />
            <Route exact path="/suppliers/:id" component={Supplier} />
            <Route exact path="/stock-take/:id" component={ProductStockTake} />
        </Switch>
    );
}

export default Routes;
