const { LOGIN, LOGOUT } = require('../actions/actionTypes');

const defaultState = {
    isAuthenticated: false,
    currentUser: null
};

const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                currentUser: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false
            };

        default:
            return state;
    }
};

export default authReducer;
