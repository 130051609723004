const inputSearchStyles = () => ({
    inputSearch: {
        width: 262,
        height: 38,
        marginRight: 4
    },
    icons: {
        marginRight: 0,
        padding: '0.25rem',
        '& svg': {
            fontSize: 24
        }
    }
});

module.exports = {
    inputSearchStyles
};
