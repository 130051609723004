import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SideBar from '../collums-components/components/common/Sidebar';
import { validateJobPermissions } from '../collums-components/helpers/index';
import Header from './header/Header';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryParams, stringifyPath } from '../services/locationHelpers';
import { setup } from '../api/config';
import AuthApi from '../api/authApi';
import { login } from '../redux/actions/authActions';
import _ from 'lodash';
import config from '../config';
import ShowPINModal from './../collums-components/components/common/PINModal';
import { COLLUMS_APPS_ACCESS_TIER } from '../collums-constants';
import { isAvailableByPlan } from '../collums-constants/utils';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import OrganisationApi from './../collums-components/api/organizationApi';
import { useCookies } from 'react-cookie';
import LogoutModal from '../collums-components/components/common/LogoutModal';
import Intercom from '../collums-components/helpers/Intercom';

import * as Sentry from '@sentry/react';
import GenericErrorBoundaryFallback from '../collums-components/components/common/GenericErrorBoundaryFallback';
import { setSentryUser } from '../collums-components/helpers/sentry';
import { validateAndUpdateClinic } from '../collums-components/helpers/user';

function App({ children }) {
    const [isInitialized, setIsInitialized] = useState(false);
    const [sentryDialogOpts, setSentryDialogOpts] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [cookies] = useCookies(['token']);

    // const fetchClinics = async () => {
    //     const clinics = await listActiveClinics();
    //     const user = await AuthApi.getMe();
    //     const allowedClinics = clinics.filter((c) => user?.data?.locations?.some((loc) => loc.clinic === c.id));
    //     return allowedClinics[0] ?? {};
    // };

    useEffect(() => {
        const doEffect = async () => {
            const queryParams = getQueryParams(location);
            const token = queryParams.token || cookies.token;
            const clinic = queryParams.clinic || localStorage.getItem('currentClinic');

            if (token) {
                try {
                    setup({ token });
                    localStorage.setItem('token', token);
                    const result = await AuthApi.getMe();
                    setSentryDialogOpts(setSentryUser(result));
                    await validateJobPermissions(token, result.data, process.env.REACT_APP_NAME);
                    await validateAndUpdateClinic(result, clinic);
                    const organisation = await OrganisationApi.getOrg();
                    localStorage.setItem('currency', organisation?.currency);

                    await Intercom.updateUser(result.data);
                    dispatch(login(result.data));
                    history.push(stringifyPath(location.pathname, _.omit(queryParams, 'token')));
                } catch (error) {
                    if (error && error.response && error.response.status === 401) {
                        window.location = `${config.authUrl}?redirect=${window.location.href}`;
                    } else {
                        throw error;
                    }
                }
            } else {
                window.location = `${config.authUrl}?redirect=${window.location.href}`;
            }

            // if (!_.isEmpty(clinic)) {
            //     localStorage.setItem('currentClinic', clinic);
            // } else {
            //     const clinic = await fetchClinics();
            //     if (!_.isEmpty(clinic)) {
            //         localStorage.setItem('currentClinic', clinic.id);
            //         window.location.reload();
            //     }
            // }

            setIsInitialized(true);
        };

        doEffect();

        // eslint-disable-next-line
    }, []);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.STOCK)) {
        return <EnvironmentDisabled />;
    }

    if (!(isInitialized && localStorage.getItem('currentClinic'))) {
        return null;
    }

    return (
        <>
            <ShowPINModal
                onEnterPin={async (user, token) => {
                    setup({ token });
                }}
            />
            {cookies.logoutActive === 'true' && <LogoutModal />}
            <div className="content-flex">
                <div className="content-width">
                    <Header />
                    <Sentry.ErrorBoundary
                        fallback={<GenericErrorBoundaryFallback />}
                        showDialog
                        dialogOptions={sentryDialogOpts}
                    >
                        <div>{children}</div>
                    </Sentry.ErrorBoundary>
                </div>
                <SideBar appName="stock" />
            </div>
        </>
    );
}

App.propTypes = {
    children: PropTypes.any.isRequired
};

export default App;
