/*eslint-disable*/
export default function mockGetSuppliers() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
                status: 200,
                brands: [
                    {
                        suppliers: [
                            {
                                _id: '1',
                                name: 'Wigmore',
                                contactName: 'Stephanie Walker',
                                address: '3 Wigmore St, London, W1 2ER',
                                email: 'stephanie@wigmore.com',
                                telephone: '0205643456'
                            },
                            {
                                _id: '2',
                                name: "L'Oreal",
                                contactName: 'Stephan Soarer',
                                address: '4 Loreal St, London, W2 5FS',
                                email: 'stephan@loreal.com',
                                telephone: '0123456678'
                            }
                        ],
                        brands: 'ZO Medik8 SkinCeuticals'
                    },
                    {
                        suppliers: [
                            {
                                _id: '2',
                                name: "L'Oreal",
                                contactName: 'Stephan Soarer',
                                address: '4 Loreal St, London, W2 5FS',
                                email: 'stephan@loreal.com',
                                telephone: '0123456678'
                            }
                        ],
                        brands: 'SkinCeuticals'
                    }
                ]
            });
        }, 300);
    });
}

export const model = {
    name: '',
    code: '',
    description: '',
    address: '',
    address2: '',
    city: '',
    county: '',
    postCode: '',
    telephone: '',
    email: '',
    contactName: '',
    brands: []
};
