import Axios from './config';

class StaffApi {
    static listPractitioners() {
        return Axios({
            method: 'GET',
            url: '/practitioners'
        }).then((res) => res.data);
    }
}

export default StaffApi;
