import Axios from './config';

class SuppliersApi {
    static getAll() {
        return Axios({
            method: 'GET',
            url: 'suppliers'
        })
            .then((res) => res)
            .catch((err) => err);
    }

    static search(queryString) {
        return Axios({
            method: 'GET',
            url: `suppliers/filters?search=${queryString}`
        })
            .then((res) => res)
            .catch((err) => err);
    }

    static searchById(id) {
        return Axios({
            method: 'GET',
            url: `suppliers/search/${id}`
        })
            .then((res) => res)
            .catch((err) => err);
    }

    static update(id, data) {
        return Axios({
            method: 'PUT',
            url: `suppliers/${id}`,
            data
        })
            .then((res) => res)
            .catch((err) => err);
    }

    static getBrands() {
        return Axios({
            method: 'GET',
            url: 'stocks/brands'
        }).catch((err) => err);
    }

    static addNew(newItem) {
        return Axios({
            method: 'POST',
            url: 'suppliers',
            data: { ...newItem }
        }).catch((err) => err);
    }
}

export default SuppliersApi;
