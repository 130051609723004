export const validateAddNewSupplier = (data) => {
    if (data.name && data.contactName && data.address && data.telephone) {
        return true;
    } else {
        return false;
    }
};

export const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
};

export const getFormattedTime = (timestamp) => {
    const date = new Date(timestamp);
    return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};
