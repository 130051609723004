import Axios from 'axios';
import querystring from 'query-string';
import _ from 'lodash';

const config = {
    backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://staging.api.collums.co.uk',
    token: null
};

export const setup = ({ token }) => {
    config.token = token;
};

export default (options) =>
    Axios({
        ...options,
        headers: {
            Authorization: config.token,
            ...(options.headers || {})
        },
        baseURL: config.backendUrl,
        paramsSerializer: (params) => {
            const paramsObject = _.pickBy(params, (value) => null != value);
            return _.size(paramsObject) ? querystring.stringfy(paramsObject) : '';
        }
    });
