const addProductFields = [
    { id: 'productName', label: 'Product Name' },
    { id: 'brand', label: 'Brand' },
    { id: 'vol', label: 'Vol' },
    { id: 'stock', label: 'Stock' },
    { id: 'usedSinceLastOrder', label: 'Used Since Last Order' },
    { id: 'lastOrderQty', label: 'Last Order Qty' },
    { id: 'min', label: 'Min' },
    { id: 'max', label: 'Max' },
    { id: 'qty', label: 'Qty' },
    { id: 'unitPrice', label: 'Unit Price' },
    { id: 'net', label: 'Net' },
    { id: 'taxPercentage', label: 'Tax %' },
    { id: 'tax', label: 'Tax' },
    { id: 'gross', label: 'Gross' },
    { id: 'notes', label: 'Notes' },
    { id: 'actions', label: '' }
];

export default addProductFields;
