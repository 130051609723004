import {
    Dialog,
    DialogTitle,
    Box,
    DialogContent,
    Button,
    TextField,
    Typography,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import OrderApi from '../../api/orderApi';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const confirmStyle = {
    backgroundColor: '#b41778',
    borderRadius: 0,
    color: 'white',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#912d6b'
    },
    padding: 10,
    fontSize: 12,
    marginLeft: 10
};

const cancelButton = {
    borderColor: '#575A64',
    borderWidth: 1,
    borderStyle: 'solid',
    textTransform: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
    },
    padding: 10,
    fontSize: 12
};

const closeButton = {
    position: 'absolute',
    right: 5,
    top: 0,
    color: 'grey'
};

function PurchaseOrder({ onClose, data = {}, onCloseAll, modalType }) {
    const history = useHistory();
    const [type, setType] = useState(modalType);
    const [orderId, setOrderId] = useState('');

    const RaiseOrder = () => {
        const raiseAction = async () => {
            try {
                const { status, products, createdby, location, supplier } = data;
                const filteredProducts = products.map((product) => {
                    return {
                        product: product.id,
                        quantity: product.orderQuantity,
                        notes: product.notes
                    };
                });
                const raisedOrder = await OrderApi.createOrder({
                    status,
                    products: filteredProducts,
                    createdby,
                    location,
                    supplier
                });
                setOrderId(raisedOrder._id);
                toastr.success('Success', 'Order successfuly raised!');
                setType('log');
            } catch (err) {
                toastr.error('Error', 'Something went wrong');
            }
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Raise Order</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>
                        Once you raise an order, you will not be able to edit it.
                    </Typography>
                    <Typography style={{ textAlign: 'center' }}>
                        After sending to your supplier, click update next to the order to update its status to awaiting
                        delivery.
                    </Typography>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button style={confirmStyle} onClick={raiseAction}>
                            Raise
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const EmailPurchaseOrder = () => {
        const [emailList, setEmailList] = useState('');
        const emailAction = () => {
            return;
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Email Purchase Order</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ width: '100%' }}
                        label="Emails"
                        placeholder="Separate email addresses with commas"
                        value={emailList}
                        onChange={(evt) => setEmailList(evt.target.value)}
                    />
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button style={confirmStyle} onClick={emailAction}>
                            Email order
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const OrderLog = () => {
        const [rows, setRows] = useState([]);
        const fetchLog = async () => {
            try {
                const orderHistory = await OrderApi.ordersHistoryList(orderId);
                setRows(orderHistory);
            } catch (err) {
                return;
            }
        };
        useEffect(() => {
            fetchLog();
        }, []);
        const returnToOrders = () => {
            onCloseAll();
            history.push('/orders');
        };
        const RenderData = ({ row, index }) => {
            return (
                <TableRow style={{ backgroundColor: index % 2 ? '#c2c2c2' : 'white' }}>
                    <TableCell style={{ textAlign: 'center' }}>
                        {moment(row.createdAt).format('D/M/YY HH:mm')}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.practitioner.displayName}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.status}</TableCell>
                </TableRow>
            );
        };

        RenderData.propTypes = {
            row: PropTypes.object.isRequired,
            index: PropTypes.number.isRequired
        };

        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={returnToOrders}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Purchase Order 1234 - Log</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead style={{ backgroundColor: '#a3a3a3' }}>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center' }}>Time & Date</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Staff</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <RenderData key={row.id} row={row} index={index} />
                            ))}
                        </TableBody>
                    </Table>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={returnToOrders}>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };
    switch (type) {
        case 'log':
            return <OrderLog />;
        case 'email':
            return <EmailPurchaseOrder />;
        default:
            return <RaiseOrder />;
    }
}

PurchaseOrder.propTypes = {
    data: PropTypes.object,
    modalType: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onCloseAll: PropTypes.func.isRequired
};

export default PurchaseOrder;
