import Axios from './config';

class StockTakeApi {
    static listStockTake({ archived, brandFilter, status, performedBy, page, pageSize, search, clinic, sort }) {
        const query = `?archived=${archived || false}${brandFilter ? `&brand=${brandFilter.id}` : ''}${
            status ? `&status=${status}` : ''
        }${performedBy ? `&performedBy=${performedBy.id}` : ''}${page ? `&skip=${page}` : ''}${
            pageSize ? `&count=${pageSize}` : ''
        }${search ? `&name=${search}` : ''}${sort ? `&sortBy=${sort.active}&sortDirection=${sort.direction}` : ''}${
            clinic ? `&clinic=${clinic}` : ''
        }`;
        return Axios({
            method: 'GET',
            url: `/stock-take${query}`
        }).then((res) => res.data);
    }

    static changeProducts(id, products, placeholder) {
        return Axios({
            method: 'POST',
            url: `/stock-take/${id}`,
            data: {
                products,
                placeholder
            }
        }).then((res) => res.data);
    }

    static create(data) {
        return Axios({
            method: 'POST',
            url: '/stock-take',
            data
        }).then((res) => res.data);
    }

    static archive(id) {
        return Axios({
            method: 'PUT',
            url: `/stock-take/${id}/archive`
        }).then((res) => res.data);
    }

    static deleteStockTake(id) {
        return Axios({
            method: 'DELETE',
            url: `/stock-take/${id}`
        }).then((res) => res.data);
    }

    static getStockTake({
        id,
        page,
        pageSize,
        products,
        brand,
        category,
        supplier,
        barcode,
        filterBy,
        sortDirection,
        name,
        clinic,
        update = false
    }) {
        const url = encodeURI(
            `${id}?${brand ? `brand=${brand}&` : ''}${category ? `category=${category}&` : ''}${
                supplier ? `supplier=${supplier}&` : ''
            }${barcode ? `barcode=${barcode}&` : ''}${name ? `name=${name}&` : ''}`
        );

        return Axios({
            method: 'PUT',
            url: `/stock-take/${url}`,
            data: {
                limit: pageSize,
                skip: page,
                products,
                filterBy,
                sortDirection,
                update,
                clinic
            }
        }).then((res) => res.data);
    }

    static registerTake(id, action, products, performedBy = '', clinic) {
        return Axios({
            method: 'PUT',
            url: `/stock-take/register-take/${id}`,
            data: {
                action,
                products,
                performedBy,
                clinic
            }
        }).then((res) => res.data);
    }

    static haveDiscrepancies(id) {
        return Axios({
            method: 'GET',
            url: `/stock-take/${id}/discrepancies`
        }).then((res) => res.data);
    }
}

export default StockTakeApi;
