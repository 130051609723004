export const STOCK_REASON_INCREASE = {
    DELIVERY: 'Delivery',
    RETURNED: 'Returned',
    TRANSFER_FROM_PROFESSIONAL: 'Transfer from professional stock',
    ORDER_DELIVERY: 'Order delivery',
    OTHER: 'Other - leave notes below'
};
export const STOCK_REASON_DECREASE = {
    THEFT: 'Theft',
    DAMAGED: 'Damaged',
    LOST: 'Lost',
    OUT_OF_DATE: 'Out of date',
    TRANSFER_TO_PROFESSIONAL: 'Transfer to professional stock',
    GIVEN_TO_STAFF: 'Given to staff',
    WASTE: 'Waste',
    OTHER: 'Other - leave notes below'
};
