import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Modal,
    Button,
    Typography,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Link
} from '@material-ui/core';
import StyledTableRow from '../common/StyledTableRow';
import { stockHistoryStyles as styles } from './styles';
import CloseBtn from '../common/CloseBtn';
import { getFormattedDate, getFormattedTime } from '../../services/helpers';
import StockApi from '../../api/stockApi';
import InvoiceModal from '../../collums-components/components/common/InvoiceModal/index';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';

function StockHistoryModal({ classes, clinic, isVisible, setIsVisible, product }) {
    const [data, setData] = useState([]);
    const [invoice, setInvoice] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getProductHistory = async () => {
            try {
                setIsLoading(true);
                await StockApi.getProductStockHistory(product.id, clinic).then(setData);
            } catch (error) {
                toastr.error(error?.data?.message || 'Something went wrong');
            } finally {
                setIsLoading(false);
            }
        };
        getProductHistory();
    }, [product.id, clinic]);

    const closeModal = () => {
        setData([]);
        setIsVisible(false);
    };

    const openInvoice = (invoiceId) => {
        setInvoice({
            id: invoiceId
        });
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal open={isVisible} onClose={closeModal} className={classes.modal}>
                <div className={classes.root}>
                    <div className={classes.closeBtn}>
                        <CloseBtn onClick={closeModal} />
                    </div>
                    <Typography variant="h4">{`Stock History - ${product.name || ''}`}</Typography>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time & Date</TableCell>
                                <TableCell>Staff</TableCell>
                                <TableCell>Change</TableCell>
                                <TableCell>Stock</TableCell>
                                <TableCell>Reason</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.map((item) => {
                                    const description = (() => {
                                        if (item.reason.link) {
                                            return (
                                                <Link href={`${item.reason.link}`} target="_blank">
                                                    {item.reason.description}
                                                </Link>
                                            );
                                        }
                                        if (item.invoice) {
                                            return (
                                                <Link onClick={() => openInvoice(item.invoice)}>
                                                    {item.reason.description}
                                                </Link>
                                            );
                                        }
                                        return (
                                            <p>
                                                {item.reason.description} {item.notes && <> - {item.notes} </>}
                                            </p>
                                        );
                                    })();

                                    return (
                                        <StyledTableRow key={item.createdAt}>
                                            <TableCell>{`${getFormattedDate(item.createdAt)}, 
                                            ${getFormattedTime(item.createdAt)}`}</TableCell>
                                            <TableCell>{item.staff && item.staff.displayName}</TableCell>
                                            <TableCell>{item.offset}</TableCell>
                                            <TableCell>{item.stockAtStage}</TableCell>
                                            <TableCell>{description}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <div className={classes.actions}>
                        <Button onClick={closeModal} variant="outlined">
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
            {invoice && (
                <InvoiceModal
                    invoice={invoice}
                    closeInvoiceModal={() => setInvoice(null)}
                    loadInvoice={(inv) => {
                        setInvoice(null);
                        setTimeout(() => setInvoice(inv), 200);
                    }}
                />
            )}
        </>
    );
}

StockHistoryModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    clinic: PropTypes.string.isRequired,
    classes: PropTypes.object
};

export default withStyles(styles)(StockHistoryModal);
