import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import { stockTakeAllViewStyles as styles } from '../stockTake/styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import {
    makeStyles,
    withStyles,
    Box,
    Typography,
    TextField,
    IconButton,
    CircularProgress,
    Checkbox,
    Button
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import ProductStockTakeTable from './ProductStockTakeTable';
import ProductStockTakeModal from './ProductStockTakeModal';

import stockApi from '../../api/stockApi';
import StockTakeApi from '../../api/stockTakeApi';
import SuppliersApi from '../../api/suppliersApi';

import stockTakeColumns from './stockTakeColumns';
import { getQueryParams } from '../../services/locationHelpers';
import useDebounce from '../../services/hooks';

import { STOCK_TAKE } from '../../collums-constants';
import exportCsv from '../../collums-components/helpers/exportCSV';
import { toLocaleString } from '../../collums-components/helpers/index';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';

function ProductStockTake({ match, classes }) {
    const location = useLocation();
    const stockId = match.params.id;
    const queryParams = getQueryParams(location);
    const clinic = queryParams.clinic;
    const isSubmited = useRef(false);
    const barcode = useRef('');
    const [isScanning, setIsScanning] = useState(false);
    const [page, setPage] = useState(0);
    const [brands, setBrands] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [type, setType] = useState('cancel');
    const [pageSize, setPageSize] = useState(25);
    const [suppliers, setSuppliers] = useState([]);
    const [stockTake, setStockTake] = useState({});
    const [numOfItems, setNumOfItems] = useState(0);
    const [categories, setCategories] = useState([]);
    const [status, setStatus] = useState('Count paused');
    const [currProducts, setCurrProducts] = useState([]);
    const [tableType, setTableType] = useState('SUBMIT');
    const [categorySearch, setCategorySearch] = useState('');
    const [brandOption, setBrandOption] = useState(undefined);
    const [brandSearch, setBrandSearch] = useState(undefined);
    const [barcodeSearch, setBarcodeSearch] = useState('');
    const [productsOnModal, setProductsOnModal] = useState([]);
    const [categoryOption, setCategoryOption] = useState(undefined);
    const [supplierOption, setSupplierOption] = useState(undefined);
    const [blindCount, setBlindCount] = useState(true);
    const [keepUncounted, setKeepUncounted] = useState(false);
    const [canceledStockTake, setCanceledStockTake] = useState('');
    const [search, setSearch] = useState('');
    const [searchName, setSearchName] = useState('');
    const searchDebounced = useDebounce(searchName, 600);
    // let email = useSelector((state) => (state.auth.currentUser ? state.auth.currentUser.email.address : undefined));
    const history = useHistory();
    const globalButtonStyles = makeStyles(modalsButtonStyles)();
    const globalInputSearchStyles = makeStyles(inputSearchStyles)();

    const [sort, setSort] = useState({
        direction: 'asc',
        active: 'brand'
    });

    const loadData = () => {
        const filters = {
            id: stockId,
            page,
            pageSize,
            products: currProducts
        };
        if (brandOption) filters.brand = brandOption.id;
        if (categoryOption) filters.category = categoryOption.id;
        if (supplierOption) filters.supplier = supplierOption.id;
        if (barcodeSearch !== '') filters.barcode = barcodeSearch;
        if (searchName !== '') filters.name = searchName;
        if (clinic) filters.clinic = clinic;
        filters.filterBy = sort.active;
        filters.sortDirection = sort.direction === 'asc' ? 1 : -1;

        StockTakeApi.getStockTake(filters).then((res) => {
            setStatus(res.status);
            if (res.status !== STOCK_TAKE.STATUS.COUNT_PAUSED) {
                setTableType('FINISHED');
            }
            setCurrProducts(res.products.map((product) => ({ ...product, isEdited: false })));
            setNumOfItems(res.size);
            setStockTake(res);
        });
    };

    const barcodeEvent = useCallback(
        async (e) => {
            if (isOpen) return;

            if (e.key === 'Enter' && e.target.id !== 'count-autocomplete' && e.target.id !== 'search-input') {
                setBarcodeSearch(barcode.current);
                barcode.current = '';
            } else {
                if (e.key !== 'Backspace') barcode.current += e.key;
            }

            if (!isScanning) {
                setIsScanning(true);
                setTimeout(() => {
                    barcode.current = '';
                    setIsScanning(false);
                }, 5000);
            }
        },
        [isOpen, isScanning]
    );

    useEffect(() => {
        window.addEventListener('keydown', barcodeEvent);
        return () => {
            window.removeEventListener('keydown', barcodeEvent);
        };
    }, [barcodeEvent]);

    useEffect(() => {
        if (!canceledStockTake) {
            loadData();
        }
        /*eslint-disable-next-line */
    }, [
        page,
        pageSize,
        brandOption,
        categoryOption,
        supplierOption,
        barcodeSearch,
        sort,
        searchDebounced,
        canceledStockTake
    ]);

    useEffect(() => {
        setProductsOnModal(currProducts);
        isSubmited.current = false;
    }, [currProducts]);

    useEffect(() => {
        stockApi.listProductCategories().then(setCategories);
    }, [categorySearch]);

    useEffect(() => {
        stockApi.getAllBrands().then(setBrands);
    }, [brandSearch]);

    useEffect(() => {
        SuppliersApi.getAll().then((res) => setSuppliers(res.data));
    }, []);

    const getAllRows = useCallback(async () => {
        const filters = {
            id: stockId,
            page: 0,
            pageSize: numOfItems,
            products: currProducts
        };
        if (brandOption) filters.brand = brandOption.id;
        if (categoryOption) filters.category = categoryOption.id;
        if (supplierOption) filters.supplier = supplierOption.id;
        if (barcodeSearch !== '') filters.barcode = barcodeSearch;
        if (clinic) filters.clinic = clinic;

        filters.filterBy = sort.active;
        filters.sortDirection = sort.direction === 'asc' ? 1 : -1;

        return await StockTakeApi.getStockTake(filters).then((res) => {
            return res.products;
        });
    }, [
        barcodeSearch,
        brandOption,
        categoryOption,
        currProducts,
        numOfItems,
        sort.active,
        sort.direction,
        stockId,
        supplierOption,
        clinic
    ]);

    const tableChange = (products) => {
        if (haveDiscrepancy) {
            saveProducts(products);
            setCurrProducts(products);
            setProductsOnModal(products);
            setOpen(true);
            setType('update');
        } else {
            sendProducts(currProducts);
            closeModal();
        }
    };

    const resumeCount = async () => {
        try {
            //await StockTakeApi.registerTake(stockId, STOCK_TAKE.STATUS.STOCK_UPDATE, productsOnModal, email);
            //toastr.success("Success", "Stock count successfuly completed!")
            setTableType('SUBMIT');
        } catch (err) {
            //toastr.error("Error", "Something went wrong");
        }
    };

    const haveDiscrepancy = useMemo(() => {
        const someDiscrepancy = currProducts.some((product) => {
            if (!product.autoStockControl) return false;
            if (product.stock !== 0 && !product.count) {
                return true;
            }
            return product.count && parseInt(product.count) !== (product.stock || 0);
        });
        return someDiscrepancy;
    }, [currProducts]);

    if (!Object.keys(stockTake).length) return <CircularProgress />;

    const openModal = (modalType) => {
        setOpen(true);
        setType(modalType);
    };

    const submitProducts = async (products = currProducts) => {
        const filters = {
            id: stockId,
            page,
            pageSize,
            products: currProducts,
            clinic: clinic
        };
        await StockTakeApi.getStockTake(filters);
        if (!keepUncounted) {
            let haveUncounted;
            await Promise.all(
                await products.map((product) => {
                    if (product.count === undefined && product.autoStockControl) haveUncounted = true;
                    return product;
                })
            );
            if (haveUncounted) {
                setKeepUncounted(true);
                setOpen(true);
                setType('uncounted');
                return;
            }
        }

        /* if (haveDiscrepancy()) {
            setProductsOnModal(products);
            setOpen(true);
            setType('discrepancies');
            return;
        }*/
        tableChange(products);
    };

    const saveProducts = async (products, update = false) => {
        const filters = {
            id: stockId,
            page,
            pageSize,
            clinic,
            products: (products || []).map((product) => ({
                ...product,
                stock: product.stock || 0
            }))
        };
        if (brandOption) filters.brand = brandOption.id;
        if (categoryOption) filters.category = categoryOption.id;
        await StockTakeApi.getStockTake({ ...filters, update });
        if (update) history.push('/stock-take');
    };

    const sendProducts = async (products) => {
        try {
            saveProducts(products, true);
            setCurrProducts(products);
            isSubmited.current = true;
            setTableType('FINISHED');
            //toastr.success('Success', 'Stock Take products successfuly updated!');
        } catch (err) {
            //toastr.error('Error', 'Something went wrong!');
        }
    };

    const closeModal = () => {
        setKeepUncounted(false);
        setOpen(false);
        setType('cancel');
    };

    const calcDiscrepancy = (count, expected, isVisible = false) => {
        if (count === undefined) {
            return '';
        }
        const result = (count || 0) - (expected || 0);
        if (isVisible && isNaN(result)) {
            return expected || 0;
        }
        return result;
    };

    const getFormattedData = async () => {
        const allProducts = await getAllRows();
        const _products = allProducts.map((product) => ({
            ...product,
            discrepancy: calcDiscrepancy(product.count, product.stock, true)
        }));

        const data = _products.map((row) => {
            const formattedValues = Object.keys(row).map((name) => {
                if (typeof row[name] === 'object') {
                    return row[name].name;
                }
                if (typeof row[name] !== 'number') {
                    return row[name];
                }

                const column = stockTakeColumns.find((col) => col.field === name);

                if (!column) return toLocaleString(row[name]);
                if (column.type === 'currency') return toLocaleString(row[name]);

                return row[name];
            });
            const newRow = { ...row };
            Object.keys(newRow).forEach((name, index) => {
                newRow[name] = formattedValues[index];
            });
            return newRow;
        });
        return data;
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                marginBottom="16px"
                marginTop="2%"
                width="54%"
                justifyContent="space-between"
            >
                <Box marginLeft="3%">
                    <IconButton
                        onClick={async () =>
                            exportCsv({
                                data: await getFormattedData(),
                                tableInfo: {
                                    title: stockTake.name,
                                    brand: brandOption,
                                    category: categoryOption,
                                    barcode: barcodeSearch
                                },
                                columns: stockTakeColumns
                            })
                        }
                    >
                        <GetAppIcon />
                    </IconButton>
                </Box>
                <Box>
                    <Typography variant="h2">{`${stockTake.name} Count`}</Typography>
                </Box>
            </Box>
            <Box margin="0 2% 0 3%">
                <Box
                    display="flex"
                    flexDirection="row"
                    marginBottom="16px"
                    paddingRight="8px"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems="center"
                >
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        marginBottom="16px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        alignItems="center"
                    >
                        <div className={classes.searchRow}>
                            <TextField
                                id={'search-input'}
                                label="Search product"
                                variant="outlined"
                                onChange={(e) => {
                                    setSearchName(e.target.value);
                                    setSearch(e.target.value);
                                }}
                                value={search}
                                className={globalInputSearchStyles.inputSearch}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            <IconButton
                                                className={globalInputSearchStyles.icons}
                                                onClick={() => {
                                                    setSearchName('');
                                                    setSearch('');
                                                }}
                                                disabled={!searchName}
                                            >
                                                <ClearIcon
                                                    style={
                                                        !searchName
                                                            ? { color: 'transparent', fontSize: 24 }
                                                            : { fontSize: 24 }
                                                    }
                                                />
                                            </IconButton>
                                            <IconButton position="end" className={globalInputSearchStyles.icons}>
                                                <SearchIcon style={{ fontSize: 24 }} />
                                            </IconButton>
                                        </>
                                    )
                                }}
                            />
                            {tableType !== 'FINISHED' && (
                                <Box className={classes.checkboxContainer}>
                                    <Checkbox
                                        checked={blindCount}
                                        onChange={() => {
                                            setBlindCount(!blindCount);
                                        }}
                                        name="blindCount"
                                    />
                                    <Typography>Blind count</Typography>
                                </Box>
                            )}
                        </div>
                        <div className={classes.filtersRow}>
                            <Autocomplete
                                className={classes.filters}
                                id={'suppliers'}
                                options={suppliers}
                                value={supplierOption}
                                onChange={(e, value) => setSupplierOption(value)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Suppliers" variant="outlined" />}
                            />

                            <Autocomplete
                                className={classes.filters}
                                id={'brands'}
                                options={brands}
                                value={brandOption}
                                onInputChange={(e) => setBrandSearch(e.target.value)}
                                onChange={(e, value) => setBrandOption(value)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Brands" variant="outlined" />}
                            />

                            <Autocomplete
                                className={classes.filters}
                                id={'performedBy'}
                                options={categories}
                                value={categoryOption}
                                onInputChange={(e) => setCategorySearch(e.target.value)}
                                onChange={(e, value) => setCategoryOption(value)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Categories" variant="outlined" />
                                )}
                            />

                            <TextField
                                className={classes.filters}
                                variant="outlined"
                                label="Barcode"
                                value={barcodeSearch}
                                onChange={(e) => {
                                    setBarcodeSearch(e.target.value);
                                }}
                            />
                        </div>
                    </Box>
                </Box>

                <Box>
                    <ProductStockTakeTable
                        numOfItems={numOfItems}
                        tableData={currProducts}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        setCurrProducts={setCurrProducts}
                        currProducts={tableType === 'SUBMIT' ? currProducts : productsOnModal}
                        tableType={tableType}
                        blindCount={blindCount}
                        setSort={setSort}
                        sort={sort}
                        calcDiscrepancy={calcDiscrepancy}
                    />
                    <div style={{ display: 'flex', marginBottom: 20 }}>
                        {tableType === 'SUBMIT' ? (
                            <>
                                <Button className={globalButtonStyles.cancelButton} onClick={() => openModal('cancel')}>
                                    Cancel
                                </Button>
                                <Button
                                    className={globalButtonStyles.baseButton}
                                    style={{ backgroundColor: '#303030', color: 'white' }}
                                    onClick={() => openModal('pause')}
                                >
                                    Pause
                                </Button>
                                <Button
                                    className={globalButtonStyles.confirmButton}
                                    onClick={() => submitProducts(currProducts, true)}
                                >
                                    Submit count
                                </Button>
                            </>
                        ) : (
                            <>
                                {status === STOCK_TAKE.STATUS.COUNT_PAUSED && (
                                    <Button className={globalButtonStyles.confirmButton} onClick={resumeCount}>
                                        Resume count
                                    </Button>
                                )}
                                {status === STOCK_TAKE.STATUS.COMPLETE && (
                                    <Button
                                        className={globalButtonStyles.confirmButton}
                                        onClick={() => openModal('update')}
                                    >
                                        Update stock
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                    {isOpen && (
                        <ProductStockTakeModal
                            clinic={clinic}
                            type={type}
                            onClose={closeModal}
                            stockId={stockId}
                            setCanceled={setCanceledStockTake}
                            currProducts={productsOnModal}
                            submitProducts={submitProducts}
                            sendProducts={sendProducts}
                            saveProducts={saveProducts}
                            tableChange={tableChange}
                            haveDiscrepancy={haveDiscrepancy}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
}

ProductStockTake.propTypes = {
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductStockTake);
