import ClinicApi from '../../api/clinicApi';
import {
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Table,
    withStyles,
    TextField,
    IconButton,
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ORDER } from '../../collums-constants/index';
import { Autocomplete } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useState, useEffect } from 'react';
import StockApi from '../../api/stockApi';
import { toLocaleString } from '../../collums-components/helpers';
import { styles } from './styles';
import OrderList from './OrderList';
import { toastr } from 'react-redux-toastr';
import addProductFields from './addProductFields';
import moment from 'moment';
import SuppliersApi from '../../api/suppliersApi';
import NewOrdersModal from './NewOrdersModal';
import { useSelector } from 'react-redux';

const actionButton = {
    borderColor: '#575A64',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 0,
    textTransform: 'none',
    marginLeft: 10,
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
    },
    padding: 5,
    fontSize: 12
};

const pinkButton = {
    backgroundColor: '#b41778',
    borderRadius: 0,
    color: 'white',
    textTransform: 'none',
    marginLeft: 10,
    '&:hover': {
        backgroundColor: '#912d6b'
    },
    padding: 6,
    fontSize: 12
};

const NewOrderForm = ({ classes }) => {
    const date = new Date();
    const [tax, setTax] = useState(0);
    const [notes, setNotes] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [type, setType] = useState('cancel');
    const [quantity, setQuantity] = useState(0);
    const [brand, setBrand] = useState(undefined);
    const [clinic, setClinic] = useState(undefined);
    const [brandsList, setBrandsList] = useState([]);
    const [clinicsList, setClinicsList] = useState([]);
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState(undefined);
    const [supplier, setSupplier] = useState(undefined);
    const [supplierList, setSupplierList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [productType, setProductType] = useState(undefined);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [productOrderList, setProductOrderList] = useState([]);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [stockFilter, setStockFilter] = useState(ORDER.STOCK_QUANTITY_TYPE.ALL_PRODUCTS);

    const stockQuantityTypes = [
        ORDER.STOCK_QUANTITY_TYPE.MIN_OR_LESS,
        ORDER.STOCK_QUANTITY_TYPE.LESS_THAN_MAX,
        ORDER.STOCK_QUANTITY_TYPE.ALL_PRODUCTS
    ];

    const openModal = (type) => {
        if (type === 'raise') {
            if (productOrderList.length === 0 || !supplier || !clinic) return;
        }
        setOpen(true);
        setType(type);
    };

    const closeModal = () => setOpen(false);

    useEffect(() => {
        const filters = {};
        if (productName !== '') filters.name = productName;
        if (supplier) filters.supplier = supplier.id;
        StockApi.getStocks(filters).then((res) => setProductsList(res.data));
    }, [productName, supplier]);

    useEffect(() => {
        SuppliersApi.getAll().then((res) => setSupplierList(res.data));
    }, []);

    useEffect(() => {
        StockApi.getAllBrands().then(setBrandsList);
    }, []);

    useEffect(() => {
        StockApi.listProductCategories().then(setCategoriesList);
    }, []);

    useEffect(() => {
        ClinicApi.listClinics().then((res) => {
            setClinicsList(res);
            if (res.length === 1) setClinic(res[0]);
        });
    }, []);

    const handleChange = (value) => {
        if (value) {
            setQuantity(value.stock ? parseInt(value.max) - parseInt(value.stock) : parseInt(value.max));
            setTax(value.tax ? value.tax : 0);
            setSelectedProduct({
                ...value,
                stock: value.stock ? value.stock : 0
            });
        } else {
            setSelectedProduct({});
        }
    };

    const calcNetPrice = (netPrice, orderQuantity) => {
        return netPrice * orderQuantity;
    };

    const calcGrossPrice = (netPrice, orderQuantity, tax) => {
        const totalTax = calcTax(netPrice, orderQuantity, tax);
        return netPrice * orderQuantity + totalTax;
    };

    const calcTax = (netPrice, orderQuantity, tax) => {
        return ((tax * netPrice) / 100) * orderQuantity;
    };

    const handleDeleteRow = (row) => {
        const newList = productOrderList.filter((item) => item.id !== row.id);
        setProductOrderList(newList);
    };

    const handleAddProduct = () => {
        if (supplier) {
            setProductOrderList([
                ...productOrderList,
                { ...selectedProduct, orderQuantity: quantity, tax: tax, notes: notes }
            ]);
            setSelectedProduct([]);
            setProductName('');
            setQuantity(0);
            setNotes('');
            setTax(0);
        } else {
            toastr.error('Supplier missing', 'Before adding items, you need to select a supplier');
        }
    };

    const handleAddManyProducts = async () => {
        if (supplier) {
            const filters = { hasPagination: false };
            if (category) filters.category = category.id;
            if (supplier) filters.supplier = supplier.id;
            if (brand) filters.brand = brand.id;
            if (stockFilter !== '') filters.stockQuantityType = stockFilter;
            if (productOrderList.length > 0)
                filters.productsOtherThan = productOrderList.map((product) => product.id).join(',');
            if (productType) filters.type = productType;
            await StockApi.getStocks(filters).then((res) => {
                const newProducts = res.data.map((product) => {
                    return {
                        ...product,
                        orderQuantity: product.stock
                            ? parseInt(product.max) - parseInt(product.stock)
                            : parseInt(product.max),
                        tax: product.tax ? product.tax : 0,
                        stock: product.stock ? product.stock : 0,
                        notes: ''
                    };
                });
                setProductOrderList([...productOrderList, ...newProducts]);
            });
        } else {
            toastr.error('Supplier missing', 'Before adding items, you need to select a supplier');
        }
    };

    const renderTableHeader = () => {
        return (
            <TableHead style={{ backgroundColor: '#a3a3a3' }}>
                <TableRow>
                    {/*eslint-disable-next-line */}
                    {addProductFields.map((cell) => {
                        return (
                            <TableCell key={cell.id} padding="default" className={classes.tableHeader}>
                                <span>{cell.label}</span>
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    };

    if (!clinicsList && !supplierList) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Box
                margin="2% 1% 1% 1%"
                display={'flex'}
                flexDirection={'row'}
                width={'60%'}
                justifyContent={'space-between'}
            >
                <Typography>Date: {moment(date).format('DD/MM/YY')}</Typography>
                <Typography>Staff: {currentUser.displayName || '-'} </Typography>
                <Typography>Payment terms: 30 days</Typography>
            </Box>
            <Box margin="2% 1% 1% 1%" display={'flex'} flexDirection={'row'}>
                <Autocomplete
                    defaultValue={supplier}
                    options={supplierList}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 150 }}
                    onChange={(e, value) => setSupplier(value)}
                    renderInput={(params) => (
                        <TextField {...params} size={'small'} label="Supplier" variant="outlined" />
                    )}
                />
                <Autocomplete
                    value={clinic || {}}
                    options={clinicsList}
                    getOptionLabel={(option) => option.accountName}
                    style={{ width: 150, marginLeft: 20 }}
                    onChange={(e, value) => setClinic(value)}
                    renderInput={(params) => (
                        <TextField {...params} size={'small'} label="Location" variant="outlined" />
                    )}
                />
            </Box>
            <Box margin="2% 1% 2% 1%">
                <Typography className={classes.title}>Add multiple products</Typography>
                <Box display={'flex'} flexDirection={'row'} width={'80%'} justifyContent={'space-between'}>
                    <Autocomplete
                        value={productType}
                        options={['Retail', 'Professional']}
                        style={{ width: 200 }}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => setProductType(value)}
                        renderInput={(params) => (
                            <TextField {...params} size={'small'} label="Type" variant="outlined" />
                        )}
                    />
                    <Autocomplete
                        value={brand}
                        options={brandsList}
                        style={{ width: 200 }}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setBrand(value)}
                        renderInput={(params) => (
                            <TextField {...params} size={'small'} label="Brand" variant="outlined" />
                        )}
                    />
                    <Autocomplete
                        value={category}
                        options={categoriesList}
                        style={{ width: 200 }}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setCategory(value)}
                        renderInput={(params) => (
                            <TextField {...params} size={'small'} label="Category" variant="outlined" />
                        )}
                    />
                    <Autocomplete
                        value={stockFilter}
                        options={stockQuantityTypes}
                        style={{ width: 200 }}
                        onChange={(e, value) => setStockFilter(value)}
                        renderInput={(params) => <TextField {...params} size={'small'} variant="outlined" />}
                    />
                    <IconButton onClick={() => handleAddManyProducts()} color="primary">
                        <AddCircleIcon style={{ color: '#115293' }} />
                    </IconButton>
                </Box>
            </Box>
            {/* Add single item row */}
            <Box margin="2% 1% 6% 1%">
                <Typography className={classes.title}>Add individual product</Typography>
                <Table className={classes.table} size="medium">
                    {renderTableHeader()}
                    <TableBody>
                        <TableRow key={'newProduct'} className={`${classes.tableRow}`}>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                <Autocomplete
                                    id="new-product-item"
                                    options={productsList}
                                    getOptionLabel={(option) =>
                                        `${option.name}, ${option.brand ? `${option.brand.name},` : ''} ${
                                            option.vol
                                        }mls, ${toLocaleString(option.netPrice)}`
                                    }
                                    style={{ width: '100%' }}
                                    onInputChange={(e) => setProductName(e.target.value)}
                                    onChange={(e, value) => handleChange(value)}
                                    renderInput={(params) => (
                                        <TextField {...params} size={'small'} label="Product" variant="outlined" />
                                    )}
                                />
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {selectedProduct.brand ? selectedProduct.brand.name : '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>{selectedProduct.vol || '-'}</TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {selectedProduct.stock >= 0 ? selectedProduct.stock : '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {selectedProduct.usedSinceLastOrder || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {selectedProduct.lastOrderQty || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>{selectedProduct.min || '-'}</TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>{selectedProduct.max || '-'}</TableCell>
                            <TableCell width={100} className={`${classes.tableBodyCell}`}>
                                {Object.keys(selectedProduct).length === 0 && '-'}
                                {Object.keys(selectedProduct).length > 0 && (
                                    <TextField
                                        id="outlined-number"
                                        type="number"
                                        inputProps={{ min: selectedProduct.min, max: selectedProduct.max }}
                                        value={quantity}
                                        onChange={(e) => {
                                            setQuantity(e.target.value);
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(selectedProduct.costPrice) || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(calcNetPrice(selectedProduct.netPrice, quantity)) || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {Object.keys(selectedProduct).length === 0 && '-'}
                                {Object.keys(selectedProduct).length > 0 && (
                                    <TextField
                                        id="outlined-number"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={tax}
                                        onChange={(e) => {
                                            setTax(e.target.value);
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(calcTax(selectedProduct.netPrice, quantity, tax)) || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(calcGrossPrice(selectedProduct.netPrice, quantity, tax)) || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {Object.keys(selectedProduct).length === 0 && '-'}
                                {Object.keys(selectedProduct).length > 0 && (
                                    <TextField
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        variant={'outlined'}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleAddProduct()} color="primary">
                                    <AddCircleIcon style={{ color: '#115293' }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            {/* Retail rows */}
            {productOrderList.find((product) => product.type === 'Retail') && (
                <Box margin="1%">
                    <Typography className={classes.title}>Retail</Typography>
                    <OrderList
                        productOrderList={productOrderList.filter((product) => product.type === 'Retail')}
                        calcNetPrice={calcNetPrice}
                        calcTax={calcTax}
                        calcGrossPrice={calcGrossPrice}
                        renderTableHeader={renderTableHeader}
                        handleDeleteRow={handleDeleteRow}
                    />
                </Box>
            )}
            {productOrderList.find((product) => product.type === 'Professional') && (
                <Box margin="1%">
                    <Typography className={classes.title}>Professional</Typography>
                    <OrderList
                        productOrderList={productOrderList.filter((product) => product.type === 'Professional')}
                        calcNetPrice={calcNetPrice}
                        calcTax={calcTax}
                        calcGrossPrice={calcGrossPrice}
                        renderTableHeader={renderTableHeader}
                        handleDeleteRow={handleDeleteRow}
                    />
                </Box>
            )}
            <Box margin="1%">
                <Table className={classes.table}>
                    <TableHead style={{ backgroundColor: '#919191' }}>
                        <TableRow>
                            <TableCell padding="default" className={classes.tableHeader}>
                                {' '}
                                Total{' '}
                            </TableCell>
                            <TableCell padding="default" className={classes.tableHeader}>
                                {' '}
                                Total Net{' '}
                            </TableCell>
                            <TableCell padding="default" className={classes.tableHeader}>
                                {' '}
                                Total Tax{' '}
                            </TableCell>
                            <TableCell padding="default" className={classes.tableHeader}>
                                {' '}
                                Total Gross{' '}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableCell className={`${classes.tableBodyCell}`}>
                            {toLocaleString(productOrderList.reduce((acc, item) => acc + item.orderQuantity, 0))}
                        </TableCell>
                        <TableCell className={`${classes.tableBodyCell}`}>
                            {toLocaleString(
                                productOrderList
                                    .reduce((acc, item) => acc + calcNetPrice(item.netPrice, item.orderQuantity), 0)
                                    .toFixed(2)
                            )}
                        </TableCell>
                        <TableCell className={`${classes.tableBodyCell}`}>
                            {toLocaleString(
                                productOrderList
                                    .reduce(
                                        (acc, item) => acc + calcTax(item.netPrice, item.orderQuantity, item.tax),
                                        0
                                    )
                                    .toFixed(2)
                            )}
                        </TableCell>
                        <TableCell className={`${classes.tableBodyCell}`}>
                            {toLocaleString(
                                productOrderList
                                    .reduce(
                                        (acc, item) =>
                                            acc + calcGrossPrice(item.netPrice, item.orderQuantity, item.tax),
                                        0
                                    )
                                    .toFixed(2)
                            )}
                        </TableCell>
                    </TableBody>
                </Table>
            </Box>
            <Box margin="1%">
                <Button
                    style={{ ...actionButton, marginLeft: 0, backgroundColor: 'white' }}
                    onClick={() => openModal('cancel')}
                >
                    Cancel
                </Button>
                <Button style={{ ...actionButton, backgroundColor: '#303030', color: 'white' }} onClick={() => 1}>
                    Save Draft
                </Button>
                <Button style={pinkButton} onClick={() => openModal('raise')}>
                    Raise order
                </Button>
            </Box>
            {isOpen && (
                <NewOrdersModal
                    products={productOrderList}
                    clinic={clinic || {}}
                    supplier={supplier || {}}
                    type={type}
                    onClose={closeModal}
                    now={date}
                />
            )}
        </Box>
    );
};

NewOrderForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewOrderForm);
