export const suppliersInfoStyles = () => ({
    body: {
        width: '100%',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '50%',
        width: '80%',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: '32px',
        margin: '5% 0 5% 0'
    },
    formItem: {
        margin: 8
    }
});
