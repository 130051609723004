export const stockTakeAllViewStyles = () => ({
    title: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500
    },
    filters: {
        width: 200,
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
            padding: 2
        }
    },
    marginRight8px: {
        marginRight: 8
    },
    searchRow: {
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    filtersRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '8px'
    },
    inputFilters: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > div': {
            '& span': {
                color: 'rgba(0, 0, 0, 0.87)'
            }
        }
    }
});

export const stockTakeAllListStyles = () => ({
    table: {
        marginTop: 16,
        '& .MuiTableCell-root': {
            padding: '0.5vw'
        },
        '& .MuiTableBody-root .Mui-selected': {
            backgroundColor: '#F6BDBE'
        }
    },
    tableHeader: {
        opacity: 1,
        fontWeight: 'bold',
        borderColor: 'black',
        borderWidth: 2
    },
    tableRow: {
        cursor: 'default'
    },
    tableName: {
        cursor: 'pointer',
        color: 'blue'
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        }
    },
    marginRight8px: {
        marginRight: 8
    },
    filters: {
        width: 200,
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
            padding: 2
        }
    },
    rightAlignCell: {
        textAlign: 'right !important',
        paddingRight: '35px !important'
    },
    rightAlignCellHeader: {
        textAlign: 'right !important'
    },
    autocompleteCell: {
        width: '200px !important',
        paddingRight: '30px !important'
    },
    autocomplete: {
        width: '100%',
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
            padding: 2
        }
    },
    autocompleteTextInputProps: {
        textAlign: 'right !important'
    }
});

export const stockTakeModalStyles = () => ({
    closeButton: { position: 'absolute', right: 5, top: 0, color: 'grey' },
    autocomplete: {
        width: '100%',
        minHeight: 38,
        '& .MuiInputBase-root': {
            padding: '2px 8px',
            paddingRight: '45px !important',
            height: 'auto !important'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    },
    autocompleteTextField: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            height: 'auto !important'
        }
    }
});
