import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { stockTakeAllViewStyles as styles } from '../stockTake/styles';

import { Box, Checkbox, CircularProgress, IconButton, TextField, Typography, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useHistory } from 'react-router-dom';

import SuppliersApi from '../../api/suppliersApi';
import ClinicApi from '../../api/clinicApi';
import OrdersTable from './OrdersTable';
import { ORDER } from '../../collums-constants/index';
import OrderApi from '../../api/orderApi';
function Orders({ classes }) {
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [numOfItems, setNumOfItems] = useState(100);
    const [pageSize, setPageSize] = useState(25);
    const [suppliers, setSuppliers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [archived, setArchived] = useState(false);
    const [ordersList, setOrdersList] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [statusOption, setStatusOption] = useState(undefined);
    const [supplierOption, setSupplierOption] = useState(undefined);
    const [locationOption, setLocationOption] = useState(undefined);

    const statusList = [
        ORDER.STATUS.DRAFT,
        ORDER.STATUS.RAISED,
        ORDER.STATUS.AWAITING_DELIVERY,
        ORDER.STATUS.PART_DELIVERED,
        ORDER.STATUS.COMPLETED
    ];

    const reloadData = async () => {
        const filters = {};
        if (search) filters.code = search;
        if (page) filters.skip = page;
        if (pageSize) filters.limit = pageSize;
        if (supplierOption) filters.supplier = supplierOption.id;
        if (locationOption) filters.location = locationOption.id;
        if (statusOption) filters.status = statusOption;

        await OrderApi.listOrders(filters).then((res) => {
            setOrdersList(res.data);
            setNumOfItems(res.numOfItems);
        });
    };

    useEffect(() => {
        SuppliersApi.getAll().then((res) => setSuppliers(res.data));
        ClinicApi.listClinics().then((res) => {
            if (res.length === 1) setLocationOption(res[0]);
            setLocations(res);
        });
    }, []);

    useEffect(() => {
        reloadData();
        /*eslint-disable-next-line */
    }, [supplierOption, locationOption, statusOption, search, page, pageSize]);

    if (locations.length === 0) return <CircularProgress />;

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                marginBottom="16px"
                marginTop="2%"
                width="54%"
                justifyContent="space-between"
            >
                <Box>
                    <IconButton>
                        <InsertDriveFileIcon />
                    </IconButton>
                    <IconButton>
                        <GetAppIcon />
                    </IconButton>
                    <IconButton>
                        <EmailIcon />
                    </IconButton>
                </Box>
                <Box>
                    <Typography variant="h2">{'Orders'}</Typography>
                </Box>
            </Box>
            <Box margin="0 2% 0 3%">
                <Box display="flex" flexDirection="row" flexWrap="wrap" marginBottom="16px" alignItems="center">
                    <div className={classes.marginRight8px}>
                        <TextField
                            label="Search product"
                            variant="outlined"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className={classes.input}
                        />
                    </div>
                    <div className={classes.marginRight8px}>
                        <IconButton onClick={() => history.push('/create-order')} color="primary">
                            <AddCircleIcon style={{ color: '#115293', fontSize: '42px' }} />
                        </IconButton>
                    </div>
                    <div className={classes.marginRight8px}>
                        <Autocomplete
                            className={classes.filters}
                            id={'suppliers'}
                            options={suppliers}
                            value={supplierOption}
                            onChange={(e, value) => setSupplierOption(value)}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Suppliers" variant="outlined" />}
                        />
                    </div>
                    <div className={classes.marginRight8px}>
                        <Autocomplete
                            className={classes.filters}
                            id={'status'}
                            options={statusList}
                            value={statusOption}
                            onChange={(e, value) => setStatusOption(value)}
                            renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                        />
                    </div>
                    <div className={classes.marginRight8px}>
                        <Autocomplete
                            className={classes.filters}
                            id={'clinics'}
                            options={locations}
                            value={locationOption}
                            onChange={(e, value) => {
                                if (locations.length !== 1) setLocationOption(value);
                            }}
                            getOptionLabel={(option) => option.accountName}
                            renderInput={(params) => <TextField {...params} label="Locations" variant="outlined" />}
                        />
                    </div>
                </Box>
                <Box display="flex" flexDirection="row" marginBottom="16px" justifyContent="flex-end">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Checkbox
                            checked={archived}
                            onChange={() => {
                                setArchived(!archived);
                            }}
                            name="filterByArchived"
                            color="primary"
                        />
                        <Typography>Show Archived</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Checkbox
                            checked={completed}
                            onChange={() => {
                                setCompleted(!completed);
                            }}
                            name="filterByCompleted"
                            color="primary"
                        />
                        <Typography>Show Completed</Typography>
                    </Box>
                </Box>
                <Box>
                    <OrdersTable
                        tableData={ordersList}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        numOfItems={numOfItems}
                        reloadData={reloadData}
                    />
                </Box>
            </Box>
        </>
    );
}

Orders.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Orders);
