import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, Box, Typography, TextField, IconButton, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import StockTakeTable from './StockTakeTable';
import { stockTakeAllViewStyles as styles } from './styles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import EmailIcon from '@material-ui/icons/Email';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { STOCK_TAKE } from '../../collums-constants/index';
import stockApi from '../../api/stockApi';
import StockTakeApi from '../../api/stockTakeApi';
import StaffApi from '../../api/staffApi';
import ClinicApi from '../../api/clinicApi';
import StockTakeModal from './stockTakeModal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import { useDebounce } from '../../collums-components/helpers';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

function StockAllView({ classes }) {
    const [tableData, setTableData] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [practitioners, setPractitioners] = useState([]);
    const [clinics, setClinics] = useState([]);
    const [selectedStockTake, setSelectedStockTake] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState('new');
    const [pageSize, setPageSize] = useState(25);
    const [archived, setArchived] = useState(false);
    const [brandFilter, setBrandFilter] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [performedBy, setPerformedBy] = useState(undefined);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [numOfItems, setNumOfItems] = useState(0);
    const [status, setStatus] = useState(undefined);
    const clinic = localStorage.getItem(CURRENT_CLINIC);

    const [sort, setSort] = useState({
        direction: 'desc',
        active: 'createdAt'
    });
    const debounceFilter = useDebounce(search, 1000);

    const statusConstants = [
        STOCK_TAKE.STATUS.COUNT_PAUSED,
        STOCK_TAKE.STATUS.COMPLETE,
        STOCK_TAKE.STATUS.STOCK_UPDATE
    ];

    const globalInputSearchStyles = makeStyles(inputSearchStyles)();
    const globalButtonsStyles = makeStyles(buttonsStyles)();

    const openCreateModal = () => {
        setType('new');
        setIsOpen(true);
    };

    const openModal = (modalType, id) => {
        setType(modalType);
        const selected = tableData.find((element) => element.id === id);
        setSelectedStockTake(selected);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedStockTake({});
        setType('new');
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await StockTakeApi.listStockTake({
                archived,
                brandFilter,
                status,
                performedBy,
                page,
                pageSize,
                search,
                clinic,
                sort
            }).then((res) => res);
            setTableData(res.items);
            setNumOfItems(res.total);
        } catch (error) {
            toastr.error(error?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        /*eslint-disable-next-line */
    }, [archived, status, brandFilter, performedBy, page, pageSize, debounceFilter]);

    useEffect(() => {
        setTableData(sortedRowInformation(tableData, getComparator(sort.direction, sort.active)));
        // eslint-disable-next-line
    }, [sort]);

    function descendingComparator(a, b, orderBy) {
        if (a[orderBy].displayName) {
            if (b[orderBy].displayName < a[orderBy].displayName) {
                return -1;
            } else if (b[orderBy].displayName > a[orderBy].displayName) {
                return 1;
            }
        }
        if (
            typeof b[orderBy] === 'string'
                ? b[orderBy].toLowerCase() < a[orderBy].toLowerCase()
                : b[orderBy] < a[orderBy]
        ) {
            return -1;
        } else if (
            typeof b[orderBy] === 'string'
                ? b[orderBy].toLowerCase() > a[orderBy].toLowerCase()
                : b[orderBy] > a[orderBy]
        ) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const sortedRowInformation = (rowArray, comparator) => {
        const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
        stabilizedRowArray.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedRowArray.map((el) => el[0]);
    };

    useEffect(() => {
        StaffApi.listPractitioners().then((res) => setPractitioners(res.items));
        ClinicApi.listClinics().then(setClinics);
        stockApi.getAllBrands().then(setBrands);
        stockApi.listProductCategories().then(setCategories);
    }, []);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Box
                display="flex"
                flexDirection="row"
                marginBottom="16px"
                marginTop="2%"
                justifyContent="center"
                alignContent="center"
            >
                {/* <Box>
                    <IconButton>
                        <InsertDriveFileIcon />
                    </IconButton>
                    <IconButton>
                        <GetAppIcon />
                    </IconButton>
                    <IconButton>
                        <EmailIcon />
                    </IconButton>
                </Box> */}
                {/* <Box> */}
                <Typography variant="h2" className={classes.title}>
                    {'Stock Take'}
                </Typography>
                {/* </Box> */}
            </Box>
            <Box margin="0 2% 0 3%">
                <Box display="flex" flexDirection="row" flexWrap="wrap" marginBottom="16px" alignItems="center">
                    <div className={classes.marginRight8px}>
                        <TextField
                            variant="outlined"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <IconButton
                                            className={globalInputSearchStyles.icons}
                                            onClick={() => setSearch('')}
                                            disabled={!search}
                                        >
                                            <ClearIcon
                                                style={
                                                    !search ? { color: 'transparent', fontSize: 24 } : { fontSize: 24 }
                                                }
                                            />
                                        </IconButton>
                                        <IconButton position="end" className={globalInputSearchStyles.icons}>
                                            <SearchIcon style={{ fontSize: 24 }} />
                                        </IconButton>
                                    </>
                                )
                            }}
                        />
                    </div>
                    <div className={classes.marginRight8px}>
                        <IconButton onClick={openCreateModal} color="primary">
                            <AddCircleIcon className={globalButtonsStyles.addCircleStyle} />
                        </IconButton>
                    </div>
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                    <div className={classes.inputFilters}>
                        <div className={classes.marginRight8px}>
                            <Autocomplete
                                className={classes.filters}
                                id={'brands'}
                                options={brands}
                                onInputChange={(e) => setBrandFilter(e.target.value)}
                                onChange={(e, value) => setBrandFilter(value)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Brands" variant="outlined" />}
                            />
                        </div>
                        <div className={classes.marginRight8px}>
                            <Autocomplete
                                className={classes.filters}
                                id={'performedBy'}
                                options={practitioners}
                                onInputChange={(e) => setPerformedBy(e.target.value)}
                                onChange={(e, value) => setPerformedBy(value)}
                                getOptionLabel={(option) => `${option.firstName} ${option.surname}`}
                                renderInput={(params) => (
                                    <TextField {...params} label="Performed By" variant="outlined" />
                                )}
                            />
                        </div>
                        <div className={classes.marginRight8px}>
                            <Autocomplete
                                className={classes.filters}
                                id={'status'}
                                options={statusConstants}
                                onInputChange={(e) => setStatus(e.target.value)}
                                onChange={(e, value) => setStatus(value)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                            />
                        </div>
                        <div className={classes.marginRight8px}>
                            {clinics.length > 1 && (
                                <Autocomplete
                                    className={classes.filters}
                                    id={'location'}
                                    options={clinics}
                                    onInputChange={() => {}}
                                    onChange={() => {}}
                                    getOptionLabel={(option) => option.accountName}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Locations" variant="outlined" />
                                    )}
                                />
                            )}
                        </div>
                    </div>

                    <div className={classes.checkboxContainer}>
                        <Checkbox
                            checked={archived}
                            onChange={() => {
                                setArchived(!archived);
                            }}
                            name="filterByArchived"
                            color="primary"
                        />
                        <Typography>Show Archived</Typography>
                    </div>
                </Box>
                <Box>
                    <StockTakeTable
                        clinic={clinic}
                        tableData={tableData}
                        numOfCategories={categories.length}
                        numOfBrands={brands.length}
                        setPage={setPage}
                        page={page}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        numOfItems={numOfItems}
                        sort={sort}
                        setSort={setSort}
                        openModal={openModal}
                    />
                    {isOpen && (
                        <StockTakeModal
                            stockTake={selectedStockTake}
                            onClose={closeModal}
                            type={type}
                            categories={categories}
                            brands={brands}
                            refreshList={fetchData}
                            clinic={clinic}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
}

StockAllView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StockAllView);
