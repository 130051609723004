import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, withStyles, Typography, TextField, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { addNewSupplierStyles } from './styles';
import { model } from '../../api/getSuppliers';
import CloseBtn from '../common/CloseBtn';
import { toastr } from 'react-redux-toastr';
import { validateAddNewSupplier } from '../../services/helpers';
import SuppliersApi from '../../api/suppliersApi';

function AddNewSupplier({ classes, isVisible, brands, setIsVisible, onCreateSuccess }) {
    const [formInput, setFormInput] = useState(model);

    const setForm = (data, key) => {
        setFormInput({
            ...formInput,
            [key]: data
        });
    };

    const sendForm = async () => {
        if (validateAddNewSupplier(formInput)) {
            formInput.brands = formInput.brands.map((brand) => {
                return brand.id;
            });
            let response = await SuppliersApi.addNew(formInput);
            if (response.status === 200) {
                setFormInput(model);
                toastr.success('Success', 'New supplier successfully added!');
                onCreateSuccess();
                closeModal();
            } else {
                toastr.error('Oops', 'Something went wrong.');
            }
        } else {
            toastr.warning('Oops', 'Please fill the entire form before creating a new supplier.');
        }
    };

    const closeModal = () => {
        setFormInput(model);
        setIsVisible(false);
    };

    return (
        <Modal open={isVisible} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={closeModal} />
                </div>
                <form className={classes.form}>
                    <Typography variant="h4">Add new supplier</Typography>
                    <div className={classes.inputContainer}>
                        <label htmlFor="name">Supplier name</label>
                        <TextField
                            id="name"
                            variant="outlined"
                            value={formInput.name}
                            onChange={(e) => setForm(e.target.value, 'name')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="code">Supplier Code</label>
                        <TextField
                            id="code"
                            variant="outlined"
                            value={formInput.code}
                            onChange={(e) => setForm(e.target.value, 'code')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="description">Description</label>
                        <TextField
                            id="description"
                            variant="outlined"
                            value={formInput.description}
                            onChange={(e) => setForm(e.target.value, 'description')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="address">Address</label>
                        <TextField
                            id="address"
                            variant="outlined"
                            value={formInput.address}
                            onChange={(e) => setForm(e.target.value, 'address')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="address2">Address2</label>
                        <TextField
                            id="address2"
                            variant="outlined"
                            value={formInput.address2}
                            onChange={(e) => setForm(e.target.value, 'address2')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="city">City</label>
                        <TextField
                            id="city"
                            variant="outlined"
                            value={formInput.city}
                            onChange={(e) => setForm(e.target.value, 'city')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="county">County</label>
                        <TextField
                            id="county"
                            variant="outlined"
                            value={formInput.county}
                            onChange={(e) => setForm(e.target.value, 'county')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="postCode">Post Code</label>
                        <TextField
                            id="postCode"
                            variant="outlined"
                            value={formInput.postCode}
                            onChange={(e) => setForm(e.target.value, 'postCode')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="telephone">Telephone</label>
                        <TextField
                            id="telephone"
                            variant="outlined"
                            value={formInput.telephone}
                            onChange={(e) => setForm(e.target.value, 'telephone')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="email">Email</label>
                        <TextField
                            id="email"
                            variant="outlined"
                            value={formInput.email}
                            onChange={(e) => setForm(e.target.value, 'email')}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <label htmlFor="contactName">Contact name</label>
                        <TextField
                            id="contactName"
                            variant="outlined"
                            value={formInput.contactName}
                            onChange={(e) => setForm(e.target.value, 'contactName')}
                        />
                    </div>
                    <div className={classes.autocompleteContainer}>
                        <label htmlFor="brands">Brands</label>
                        <Autocomplete
                            id="brands"
                            size="small"
                            fullWidth
                            autoComplete
                            autoHighlight
                            multiple
                            filterSelectedOptions
                            options={brands}
                            getOptionLabel={(option) => option.name}
                            value={formInput.brands}
                            onChange={(e, tags) => setForm(tags, 'brands')}
                            renderInput={(params) => <TextField {...params} fullWidth placeholder="Brands" />}
                        />
                    </div>
                </form>
                <div className={classes.actions}>
                    <Button onClick={closeModal} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={sendForm}>Add new supplier</Button>
                </div>
            </div>
        </Modal>
    );
}

AddNewSupplier.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    onCreateSuccess: PropTypes.func.isRequired,
    brands: PropTypes.array.isRequired,
    classes: PropTypes.object
};

export default withStyles(addNewSupplierStyles)(AddNewSupplier);
