export const filterSelectStyles = () => ({
    formControl: {
        width: 200,
        backgroundColor: '#fff'
    }
});

export const closeBtnStyles = () => ({
    button: {
        padding: 8,
        '& .material-icons': {
            width: 18,
            height: 18
        },
        '& .img': {
            width: 18,
            height: 18
        }
    }
});
