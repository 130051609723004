const headerStyles = () => ({
    noShadow: {
        boxShadow: 'none',
        minHeight: '28px',
        height: '38px',
        '& button': {
            padding: 0
        }
    },
    tabsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '28px',
        height: '38px',
        backgroundColor: '#69767E',
        padding: 0
    },
    tabIndicator: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        height: 0
    },
    mainTab: {
        opacity: 1,
        fontSize: 15,
        fontWeight: 400,
        backgroundColor: '#69767E',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-selected': {
            backgroundColor: '#565A65'
        },
        '& a': {
            color: 'white',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            whiteSpace: 'nowrap'
        },
        maxWidth: 250,
        width: 250
    }
});

const subHeaderStyles = () => ({
    subTabsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '28px',
        height: '38px',
        backgroundColor: 'white',
        padding: 0
    },
    subTabIndicator: {
        height: 7,
        backgroundColor: 'rgba(0, 0, 0, 0.87)'
    },
    subTab: {
        maxWidth: 150,
        width: 150,
        opacity: 1,
        fontSize: 16,
        fontWeight: 400,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        '&.Mui-selected': {
            '& span': {
                color: ' rgba(0, 0, 0, 0.87)'
            }
        },
        '& span': {
            color: 'rgba(0, 0, 0, 0.54)',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            whiteSpace: 'nowrap'
        },
        '&.Mui-disabled': {
            opacity: 0.3
        }
    }
});

module.exports = {
    headerStyles,
    subHeaderStyles
};
