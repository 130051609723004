import React, { useState } from 'react';
import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import { dropdownButtonStyles } from '../styles';
import PropTypes from 'prop-types';

function DropdownButton({ classes, label, options }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                className={classes.optionsButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {label}
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {(options || []).map((op) => {
                    return (
                        <MenuItem
                            key={op.label || ''}
                            onClick={() => {
                                if (op.handleClick) {
                                    op.handleClick();
                                }
                                handleClose();
                            }}
                        >
                            {op.label || ''}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

DropdownButton.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired
};

export default withStyles(dropdownButtonStyles)(DropdownButton);
