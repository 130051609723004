import Axios from './config';

class AuthApi {
    static login({ email, password }) {
        return Axios({
            method: 'POST',
            url: '/login',
            data: { email, password }
        });
    }

    static getMe() {
        return Axios({
            method: 'GET',
            url: '/user/me'
        });
    }

    static resetPassword({ email }) {
        return Axios({
            method: 'POST',
            url: '/reset-password',
            data: { email, redirect: 'collums' }
        });
    }

    static changePassword({ password, code }) {
        return Axios({
            method: 'POST',
            url: `/reset-password/${code}`,
            data: { password }
        });
    }

    static lockUser() {
        return Axios({
            method: 'POST',
            url: '/lock-user'
        });
    }

    static isUserLocked() {
        return Axios({
            method: 'GET',
            url: '/is-user-locked'
        });
    }
}

export default AuthApi;
