import {
    Dialog,
    DialogTitle,
    Box,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    makeStyles,
    withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { STOCK_REASON_INCREASE, STOCK_REASON_DECREASE } from '../../constants';
import StockApi from '../../api/stockApi';
import { toastr } from 'react-redux-toastr';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { EditStockStyles } from './styles';

function EditStockModal({ product, clinic, onClose, classes }) {
    const modalButtonClasses = makeStyles(modalsButtonStyles)();

    const [form, setForm] = useState({
        signal: '+',
        value: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    const notesRef = useRef();

    const validate = () => {
        if (!form.value) {
            toastr.error('Value is required');
            return;
        }
        if (!form.reason) {
            toastr.error('Reason is required');
            return;
        }

        if (
            (form.reason === STOCK_REASON_DECREASE.OTHER || form.reason === STOCK_REASON_INCREASE.OTHER) &&
            !notesRef.current.value
        ) {
            toastr.error('Notes is required');
            return false;
        }

        return true;
    };

    const save = () => {
        if (!validate()) return;
        const payload = {
            reason: form.reason,
            notes: notesRef.current.value,
            offset: Number(`${form.signal}${form.value}`),
            clinic
        };
        setIsLoading(true);
        StockApi.updateStock(product.id, payload)
            .then(() => {
                onClose();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Dialog open fullWidth maxWidth="xs">
            <DialogTitle>
                <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                    <b style={{ fontSize: 18 }}>Edit stock level</b>
                    <span style={{ fontSize: 12 }}>
                        {product.name} - {product.category.name}
                    </span>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box p={2} display="flex" flexDirection="column">
                    <span style={{ marginBottom: 10 }}>Current stock: {product.stock || 0}</span>
                    <Box display="flex" flexDirection="row" gridColumnGap="8px">
                        <Select
                            fullWidth
                            variant="outlined"
                            value={form.signal}
                            className={classes.select}
                            onChange={(e) => setForm((form) => ({ ...form, signal: e.target.value, reason: '' }))}
                        >
                            <MenuItem value={'+'}>Increase by:</MenuItem>
                            <MenuItem value={'-'}>Decrease by:</MenuItem>
                        </Select>
                        <Autocomplete
                            fullWidth
                            options={new Array(11).fill(null).map((_, index) => index.toString())}
                            value={form.value}
                            onChange={(e, value) => setForm((form) => ({ ...form, value }))}
                            onInputChange={(e, value) => setForm((form) => ({ ...form, value }))}
                            freeSolo
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...{
                                        ...params,
                                        inputProps: {
                                            ...params.inputProps,
                                            min: 0
                                        }
                                    }}
                                    placeholder="Value"
                                    type="number"
                                    tabIndex={6}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.value}
                                    style={{ margin: 0 }}
                                />
                            )}
                        />
                    </Box>
                    {form.value && (
                        <span style={{ margin: '8px 0' }}>
                            New stock: {(product.stock || 0) + Number(`${form.signal}${form.value}`)}
                        </span>
                    )}
                    <FormControl className={classes.formItem} style={{ marginTop: 12 }}>
                        <InputLabel required style={{ left: 10, top: -5 }} className={classes.shrinkingLabel}>
                            Reason
                        </InputLabel>
                        <Select
                            className={classes.select}
                            variant="outlined"
                            value={form.reason || ''}
                            onChange={(e) => setForm((form) => ({ ...form, reason: e.target.value }))}
                        >
                            {Object.values(form.signal === '+' ? STOCK_REASON_INCREASE : STOCK_REASON_DECREASE).map(
                                (text, index) => (
                                    <MenuItem value={text} key={index}>
                                        {text}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>

                    <TextField
                        inputRef={notesRef}
                        className={`${classes.formItem} ${classes.notes}`}
                        margin="dense"
                        placeholder={`Notes${
                            [STOCK_REASON_INCREASE.OTHER, STOCK_REASON_DECREASE.OTHER].includes(form.reason) ? ' *' : ''
                        }`}
                        variant="outlined"
                        multiline
                        rows="14"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <div className={modalButtonClasses.buttonsContainer} style={{ margin: '0px 0px 16px' }}>
                    <Button className={modalButtonClasses.cancelButton} variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className={modalButtonClasses.confirmButton} color="primary" onClick={save}>
                        Update
                    </Button>
                </div>
            </DialogActions>
            <LoadingScreen isVisible={isLoading} />
        </Dialog>
    );
}

EditStockModal.propTypes = {
    product: PropTypes.object,
    onClose: PropTypes.func,
    classes: PropTypes.object.isRequired,
    clinic: PropTypes.string.isRequired
};

export default withStyles(EditStockStyles)(EditStockModal);
