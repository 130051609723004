import { Dialog, DialogTitle, Box, DialogContent, Button, Typography, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
/* import { toastr } from 'react-redux-toastr'; */
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import StockTakeApi from '../../api/stockTakeApi';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { STOCK_TAKE } from '../../collums-constants/index';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const closeButton = {
    position: 'absolute',
    right: 5,
    top: 0,
    color: 'grey'
};

function ProductStockTakeModal({
    currProducts,
    type = 'cancel',
    onClose,
    stockId,
    submitProducts,
    sendProducts,
    tableChange,
    setCanceled,
    clinic
}) {
    const email = useSelector((state) => state.auth.currentUser.email.address);
    const history = useHistory();
    const globalButtonStyles = makeStyles(modalsButtonStyles)();
    const CancelStockTake = () => {
        const history = useHistory();
        const cancelAction = async () => {
            await StockTakeApi.deleteStockTake(stockId);
            setCanceled(stockId);
            history.push('/stock-take');
            onClose();
        };
        return (
            <CancelContinueModal
                open={true}
                onCancel={onClose}
                setOpen={() => true}
                onContinue={cancelAction}
                continueButtonText={'Cancel'}
                cancelButtonText={'Back'}
                title={'Your changes will not be saved'}
                contentText={'Are you sure you want to cancel?'}
            />
        );
    };

    const UpdateStockLevels = () => {
        const updateAction = async () => {
            sendProducts(currProducts);
            onClose();
        };

        const closeUpdate = async () => {
            // update status to completed (but not updated)
            await StockTakeApi.registerTake(stockId, STOCK_TAKE.STATUS.COMPLETE, currProducts, email, clinic);
            history.push(`/stock-take?clinic=${clinic}`);
            onClose();
        };

        return (
            <CancelContinueModal
                open={true}
                onCancel={closeUpdate}
                setOpen={() => true}
                onContinue={updateAction}
                continueButtonText={'Update'}
                cancelButtonText={'No'}
                title={'Your stock count is different to the expected count.'}
                contentText={
                    'Do you want to update product stock numbers with the count you have provided? (Items not counted will not be adjusted)'
                }
            />
        );
    };

    const PauseStockTake = () => {
        const history = useHistory();
        const pauseAction = async () => {
            try {
                await StockTakeApi.registerTake(stockId, STOCK_TAKE.STATUS.COUNT_PAUSED, currProducts, email, clinic);
                history.push('/stock-take');
                //toastr.success('Success', 'Stock successfuly paused!');
            } catch (err) {
                //toastr.error('Error', 'Something went wrong');
            }
            onClose();
        };
        return (
            <CancelContinueModal
                open={true}
                onCancel={onClose}
                setOpen={() => true}
                onContinue={pauseAction}
                continueButtonText={'Pause'}
                cancelButtonText={'Back'}
                title={'Pause Stock Take'}
                contentText={'Are you sure you want to pause?'}
            />
        );
    };

    const UncountedStockTake = () => {
        const submitAction = async () => {
            onClose();
            submitProducts();
        };

        return (
            <CancelContinueModal
                open={true}
                onCancel={onClose}
                setOpen={() => true}
                onContinue={submitAction}
                continueButtonText={'Submit'}
                cancelButtonText={'Back'}
                title={'Incomplete Stock Count'}
                contentText={'Some items have not been counted. Are you sure you want to continue?'}
            />
        );
    };

    const DiscrepanciesStockTake = () => {
        const discrepanciesAction = async () => {
            onClose();
            tableChange(currProducts);
            return;
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Stock Count Discrepancies</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>
                        Your stock count contains discrepancies. Remember to update stock levels by viewing the stock
                        take or manually adjusting individual items.
                    </Typography>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 20 }}>
                        <Button className={globalButtonStyles.confirmButton} onClick={discrepanciesAction}>
                            OK
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    switch (type) {
        case 'uncounted':
            return <UncountedStockTake />;
        case 'pause':
            return <PauseStockTake />;
        case 'discrepancies':
            return <DiscrepanciesStockTake />;
        case 'update':
            return <UpdateStockLevels />;
        default:
            return <CancelStockTake />;
    }
}

ProductStockTakeModal.propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    stockId: PropTypes.string.isRequired,
    tableChange: PropTypes.func.isRequired,
    currProducts: PropTypes.array.isRequired,
    submitProducts: PropTypes.func.isRequired,
    sendProducts: PropTypes.object.isRequired,
    setCanceled: PropTypes.func.isRequired,
    clinic: PropTypes.string.isRequired
};

export default ProductStockTakeModal;
