import React from 'react';
import PropTypes from 'prop-types';

import { stockTakeAllListStyles as styles } from '../stockTake/styles';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    withStyles,
    TextField
} from '@material-ui/core';
import { toLocaleString } from '../../collums-components/helpers/index';
import { Autocomplete } from '@material-ui/lab';

import stockTakeColumns from './stockTakeColumns';

const numberOptions = new Array(50).fill(0).map((el, index) => index + 1);

function ProductStockTakeTable({
    tableData,
    classes,
    setPage,
    page,
    setPageSize,
    pageSize,
    numOfItems,
    setCurrProducts,
    currProducts,
    tableType,
    blindCount,
    setSort,
    sort,
    calcDiscrepancy
}) {
    const handleSortFilter = (cellOrder) => {
        setSort({
            direction: sort.direction === 'asc' ? 'desc' : 'asc',
            active: cellOrder
        });
    };

    const handleSelectedRows = (row) => {
        return calcDiscrepancy(row.count, row.stock) !== 0 && row.count !== undefined && row.count !== 'NC';
    };

    const renderTableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {/*eslint-disable-next-line */}
                    {stockTakeColumns.map((cell) => {
                        const title = (
                            <TableCell
                                key={cell.field}
                                padding="default"
                                className={`${classes.tableHeader} ${
                                    cell.type === 'number' || cell.type === 'currency'
                                        ? classes.rightAlignCellHeader
                                        : ''
                                }`}
                                sortDirection={sort.direction}
                            >
                                {cell.reordable ? (
                                    <TableSortLabel
                                        direction={sort.direction}
                                        active={sort.active === cell.order}
                                        onClick={() => handleSortFilter(cell.order)}
                                    >
                                        <span>{cell.title}</span>
                                    </TableSortLabel>
                                ) : (
                                    <span>{cell.title}</span>
                                )}
                            </TableCell>
                        );
                        if (tableType === 'FINISHED' && (cell.field === 'discrepancy' || cell.field === 'stock'))
                            return title;
                        if (blindCount && (cell.field === 'discrepancy' || cell.field === 'stock')) return null;
                        if (cell.field === 'supplier' && tableType === 'FINISHED') return title;
                        else if (cell.tableType === 'ALL') return title;
                        else if (cell.tableType === tableType) return title;
                    })}
                    <TableCell />
                </TableRow>
            </TableHead>
        );
    };

    const updateStockCount = (rowId, newCount) => {
        const newArray = currProducts.map((product) => {
            if (product.id === rowId)
                return {
                    ...product,
                    count: newCount || undefined,
                    isEdited: true
                };
            return product;
        });
        setCurrProducts(newArray);
    };

    return (
        <>
            <Table className={classes.table} size="medium" stickyHeader>
                {renderTableHeader()}
                <TableBody>
                    {tableData &&
                        tableData.map((row) => {
                            const stock = row.autoStockControl ? row.stock || 0 : 'NA';
                            return (
                                <TableRow
                                    key={row.id}
                                    className={`${classes.tableRow}`}
                                    selected={handleSelectedRows(row)}
                                >
                                    {tableType === 'FINISHED' && (
                                        <TableCell className={`${classes.tableBodyCell}`}>
                                            <span>{row.supplier?.name || ''}</span>
                                        </TableCell>
                                    )}
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{row.brand?.name || ''}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{row.category?.name || ''}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        <span>{row.name || ''}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell} ${classes.rightAlignCell}`}>
                                        <span>{row.vol || 0}</span>
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell} ${classes.rightAlignCell}`}>
                                        <span>{toLocaleString(row.grossPrice || 0)}</span>
                                    </TableCell>
                                    {(!blindCount || tableType === 'FINISHED') && (
                                        <TableCell className={`${classes.tableBodyCell} ${classes.rightAlignCell}`}>
                                            <span>{stock}</span>
                                        </TableCell>
                                    )}
                                    {/*tableType === 'UPDATE' && (
                                        <TableCell className={`${classes.tableBodyCell}`}>
                                            <span>0</span>
                                        </TableCell>
                                    )*/}
                                    {tableType === 'FINISHED' && (
                                        <TableCell className={`${classes.tableBodyCell} ${classes.rightAlignCell}`}>
                                            <span>{row.count || ''}</span>
                                        </TableCell>
                                    )}
                                    {tableType === 'SUBMIT' && (
                                        <TableCell className={`${classes.tableBodyCell} ${classes.autocompleteCell}`}>
                                            <Autocomplete
                                                id="count-autocomplete"
                                                className={`${classes.autocomplete}`}
                                                options={numberOptions}
                                                disabled={!row.autoStockControl}
                                                value={row.count}
                                                freeSolo
                                                disableClearable
                                                onInput={(e) => updateStockCount(row.id, e.target.value)}
                                                onChange={(e, value) => updateStockCount(row.id, value)}
                                                getOptionLabel={(option) => option.toString()}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            classes: {
                                                                input: classes.autocompleteTextInputProps
                                                            }
                                                        }}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    )}
                                    {(!blindCount || tableType === 'FINISHED') && (
                                        <TableCell className={`${classes.tableBodyCell} ${classes.rightAlignCell}`}>
                                            <span>
                                                {row.count !== 'NC' && row.autoStockControl
                                                    ? calcDiscrepancy(row.count, row.stock, true)
                                                    : stock}
                                            </span>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={numOfItems}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(e, value) => {
                    setPage(value);
                }}
                onChangeRowsPerPage={(e) => {
                    setPage(0);
                    setPageSize(e.target.value);
                }}
                classes={{ root: classes.tablePagination }}
            />
        </>
    );
}

ProductStockTakeTable.propTypes = {
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pageSize: PropTypes.number.isRequired,
    tableData: PropTypes.array.isRequired,
    tableType: PropTypes.string.isRequired,
    setPageSize: PropTypes.func.isRequired,
    numOfItems: PropTypes.number.isRequired,
    numOfBrands: PropTypes.number.isRequired,
    currProducts: PropTypes.number.isRequired,
    setCurrProducts: PropTypes.func.isRequired,
    blindCount: PropTypes.bool.isRequired,
    setSort: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    calcDiscrepancy: PropTypes.func.isRequired
};

export default withStyles(styles)(ProductStockTakeTable);
