import Api from './config';

class InvoiceApi {
    static getInvoice(id, withRelated = false, withLogs = false) {
        return Api.request({
            method: 'GET',
            url: `/invoices/${id}?showCustomer=true${withRelated ? '&withRelated=true' : ''}${withLogs ? '&withLogs=true' : ''}`
        });
    }

    static sendEmailInvoice(id) {
        return Api.request({
            method: 'POST',
            url: `/invoices/${id}/email`,
            data: {}
        });
    }

    static getAllInvoices(customer) {
        return Api.request({
            method: 'GET',
            url: `/invoices?customer=${customer}`
        });
    }

    static loadInvoiceMetaData(customer) {
        return Api.request({
            method: 'GET',
            url: `/invoices/meta-data?customer=${customer}`
        });
    }

    static updateInvoiceStatus(invoiceId, paymentStatus, closedBy) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update-status/${invoiceId}`,
            data: {
                paymentStatus,
                closedBy
            }
        });
    }

    static invoiceRefund(invoiceId, data) {
        return Api.request({
            method: 'POST',
            url: `/invoice/${invoiceId}/refund`,
            data
        });
    }

    static updateInvoice(invoiceId, data) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update/${invoiceId}`,
            data
        });
    }

    static updateInvoiceItems(invoiceId, data) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update-items/${invoiceId}`,
            data
        });
    }

    static voidOutstandingAmount(invoiceId) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/voidOsAmount/${invoiceId}`
        });
    }
}

export default InvoiceApi;
