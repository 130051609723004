import Axios from './config';

class OrderApi {
    static listOrders({ code, skip, limit, supplier, location, status }) {
        return Axios({
            method: 'GET',
            url: `/order?${code ? `code=${code}&` : ''}${skip ? `skip=${skip}&` : ''}${limit ? `limit=${limit}&` : ''}${
                supplier ? `supplier=${supplier}&` : ''
            }${location ? `location=${location}&` : ''}${status ? `status=${status}` : ''}`
        }).then((res) => res.data);
    }

    static updateOrder(id, data) {
        return Axios({
            method: 'PUT',
            url: `/order/${id}`,
            data
        }).then((res) => res.data);
    }

    static updateOrderStatus(id, data) {
        return Axios({
            method: 'PUT',
            url: `/order-status/${id}`,
            data
        }).then((res) => res.data);
    }

    static createOrder(data) {
        return Axios({
            method: 'POST',
            url: '/order',
            data
        }).then((res) => res.data);
    }

    static archive(id) {
        return Axios({
            method: 'GET',
            url: `/order/${id}/archive`
        }).then((res) => res.data);
    }

    static deleteOrder(id) {
        return Axios({
            method: 'DELETE',
            url: `/order/${id}`
        }).then((res) => res.data);
    }

    static orderHistory(data) {
        return Axios({
            method: 'POST',
            url: '/order/history',
            data
        }).then((res) => res.data);
    }

    static ordersHistoryList(id) {
        return Axios({
            method: 'GET',
            url: `/order-history/order/${id}`
        }).then((res) => res.data);
    }
}

export default OrderApi;
