import React, { useState } from 'react';
import { Menu, Button, MenuItem, makeStyles } from '@material-ui/core';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import PropTypes from 'prop-types';

const ActionButton = ({ items, data }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (event) => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    const globalStyles = makeStyles(buttonsStyles)();

    return (
        <>
            <Button onClick={openMenu} className={globalStyles.grayButton}>
                Options
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {items.map((element, index) => {
                    switch (element.title) {
                        case 'delete':
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action('delete', data.id);
                                        closeMenu();
                                    }}
                                >
                                    Delete
                                </MenuItem>
                            );
                        case 'archive':
                            /*eslint-disable-next-line */
                            let message;
                            /*eslint-disable-next-line */
                            let archiveType = typeof data.archived;
                            if (archiveType !== 'undefined' && archiveType === 'boolean') {
                                message = data.archived ? 'Unarchive' : 'Archive';
                            } else message = 'Archive';

                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action('archive', data.id);
                                        closeMenu();
                                    }}
                                >
                                    {message}
                                </MenuItem>
                            );
                        default:
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data.id, data);
                                        closeMenu();
                                    }}
                                >
                                    {element.title
                                        ? element.title.charAt(0).toUpperCase() + element.title.slice(1)
                                        : ''}
                                </MenuItem>
                            );
                    }
                })}
            </Menu>
        </>
    );
};

ActionButton.propTypes = {
    items: PropTypes.array,
    data: PropTypes.array
};

export default ActionButton;
