import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { stockTakeAllListStyles as styles } from '../stockTake/styles';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    withStyles,
    MenuItem,
    Menu,
    IconButton,
    Box,
    Typography
} from '@material-ui/core';
import { ORDER } from '../../collums-constants/index';

import AppsIcon from '@material-ui/icons/Apps';
import moment from 'moment';
import OrdersModal from './OrdersModal';
import { toLocaleString } from '../../collums-components/helpers';
import { toastr } from 'react-redux-toastr';
import OrderApi from '../../api/orderApi';

let headerTable = [
    { id: 'orderNo', order: 'orderNo', label: 'Order No', reordable: true },
    { id: 'dateCreated', order: 'dateCreated', label: 'Date Created', reordable: true },
    { id: 'supplier', order: 'supplier', label: 'Supplier', reordable: true },
    { id: 'qty', order: 'qty', label: 'Qty', reordable: true },
    { id: 'net', order: 'net', label: 'Net', reordable: true },
    { id: 'tax', order: 'tax', label: 'Tax', reordable: true },
    { id: 'gross', order: 'gross', label: 'Gross', reordable: true },
    { id: 'location', order: 'location', label: 'Location', reordable: true },
    { id: 'status', order: 'status', label: 'Status', reordable: true }
];

function OrdersTable({ tableData, classes, setPage, page, setPageSize, pageSize, numOfItems, reloadData }) {
    const [isOpen, setOpen] = useState(false);
    const [type, setType] = useState('archive');
    const [selectedOrder, setSelectedOrder] = useState({});

    const closeModal = () => {
        setOpen(false);
    };

    const openModal = (selectedType) => {
        setOpen(true);
        setType(selectedType);
    };

    const [sort, setSort] = useState({
        direction: 'asc',
        active: 'name'
    });

    const handleSortFilter = (cellOrder) => {
        setSort({
            direction: sort.direction === 'asc' ? 'desc' : 'asc',
            active: cellOrder
        });
    };

    const changeStatus = async (id, status) => {
        try {
            const data = { status: status };
            await OrderApi.updateOrderStatus(id, data);
            toastr.success('Success', 'Order successfuly updated');
            reloadData();
        } catch (err) {
            toastr.error('Error', 'Something went wrong');
        }
    };

    const GenerateIcon = ({ row }) => {
        const { status } = row;
        switch (status) {
            case ORDER.STATUS.DRAFT:
                return <MenuItem onClick={() => openModal('delete', row)}>Delete</MenuItem>;
            case ORDER.STATUS.RAISED:
                return (
                    <>
                        <MenuItem onClick={() => openModal('archive', row)}>Archive</MenuItem>
                        <MenuItem onClick={() => openModal('update', row)}>Set as awaiting delivery</MenuItem>
                    </>
                );
            case ORDER.STATUS.AWAITING_DELIVERY:
                return (
                    <>
                        <MenuItem onClick={() => openModal('archive', row)}>Archive</MenuItem>
                        <MenuItem>Count in</MenuItem>
                        <MenuItem onClick={() => changeStatus(row.id, ORDER.STATUS.PART_DELIVERED)}>
                            Set as part received
                        </MenuItem>
                    </>
                );
            case ORDER.STATUS.PART_DELIVERED:
                return (
                    <>
                        <MenuItem onClick={() => openModal('archive', row)}>Archive</MenuItem>
                        <MenuItem>Count in</MenuItem>
                        <MenuItem onClick={() => changeStatus(row.id, ORDER.STATUS.COMPLETED)}>
                            Set as complete
                        </MenuItem>
                    </>
                );
            default:
                //case ORDER.STATUS.COMPLETED:
                return (
                    <>
                        <MenuItem onClick={() => openModal('archive', row)}>Archive</MenuItem>
                        <MenuItem>View Count</MenuItem>
                    </>
                );
        }
    };

    GenerateIcon.propTypes = {
        row: PropTypes.object.isRequired
    };

    const renderTableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {/*eslint-disable-next-line */}
                    {headerTable.map((cell) => {
                        return (
                            <TableCell
                                key={cell.id}
                                padding="default"
                                className={classes.tableHeader}
                                sortDirection={sort.direction}
                            >
                                {cell.reordable ? (
                                    <TableSortLabel
                                        direction={sort.direction}
                                        active={sort.active === cell.order}
                                        onClick={() => handleSortFilter(cell.order)}
                                    >
                                        <span>{cell.label}</span>
                                    </TableSortLabel>
                                ) : (
                                    <span>{cell.label}</span>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    };

    const RenderData = ({ row }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <TableRow key={row.id} className={`${classes.tableRow}`}>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <Typography>{row.code ? row.code : ''}</Typography>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{moment(row.createdAt).format('DD/MM/YYYY h:mm')}</span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{row.supplier.contactName}</span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{row.totalQuantity ? row.totalQuantity : ''}</span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{row.totalNetPrice ? toLocaleString(row.totalNetPrice) : ''}</span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>
                        {row.totalNetPrice && row.totalGrossPrice
                            ? toLocaleString((row.totalGrossPrice - row.totalNetPrice).toFixed(2))
                            : ''}
                    </span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{row.totalGrossPrice ? toLocaleString(row.totalGrossPrice) : ''}</span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{row.location ? row.location.accountName : ''}</span>
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`}>
                    <span>{row.status ? row.status : ''}</span>
                </TableCell>
                <TableCell>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                                setSelectedOrder(row);
                                handleClick(event);
                            }}
                        >
                            <AppsIcon />
                        </IconButton>
                        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                            <GenerateIcon row={row} />
                        </Menu>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    RenderData.propTypes = {
        row: PropTypes.object.isRequired
    };

    return (
        <>
            <Table className={classes.table} size="medium" stickyHeader>
                {renderTableHeader()}
                <TableBody>
                    {tableData &&
                        tableData.map((row) => (
                            <>
                                {/*eslint-disable-next-line */}
                                <RenderData key={row.id} row={row} />
                            </>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={numOfItems}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(e, value) => {
                    setPage(value);
                }}
                onChangeRowsPerPage={(e) => {
                    setPageSize(e.target.value);
                }}
                classes={{ root: classes.tablePagination }}
            />
            {isOpen && <OrdersModal order={selectedOrder} onClose={closeModal} type={type} reloadData={reloadData} />}
        </>
    );
}

OrdersTable.propTypes = {
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pageSize: PropTypes.number.isRequired,
    tableData: PropTypes.array.isRequired,
    setPageSize: PropTypes.func.isRequired,
    numOfItems: PropTypes.number.isRequired,
    reloadData: PropTypes.func.isRequired
};

export default withStyles(styles)(OrdersTable);
