import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { supplierSyles } from './styles';
import { SaveAlt, Search } from '@material-ui/icons';
import SuppliersApi from '../../api/suppliersApi';
import { withStyles, IconButton, TextField, InputAdornment, Button } from '@material-ui/core';
import SuppliersTable from './SuppliersTable';
import AddNewSupplier from './AddNewSupplier';

function Suppliers({ classes }) {
    const [search, setSearch] = useState('');
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [brands, setBrands] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        getBrands();
        searchSuppliers();
        // eslint-disable-next-line
    }, []);

    async function getBrands() {
        let response = await SuppliersApi.getBrands();
        if (response.status === 200) {
            setBrands([...response.data]);
        } else {
            toastr.error('Oops', 'Something went wrong.');
        }
    }

    const searchSuppliers = async () => {
        if (search !== '') {
            let response = await SuppliersApi.search(search);
            if (response.status === 200) {
                if (response.data.length === 0) {
                    toastr.warning('Invalid search', 'No supplier or contact has been found with the given string.');
                }
                setItems([...response.data]);
            } else {
                toastr.error('Oops', 'Something went wrong.');
            }
        } else {
            let response = await SuppliersApi.getAll();
            if (response.status === 200) {
                setItems([...response.data]);
            } else {
                toastr.error('Oops', 'Something went wrong.');
            }
        }
    };

    return (
        <div className={classes.root}>
            <IconButton className={classes.saveButton}>
                <SaveAlt />
            </IconButton>
            <TextField
                className={classes.searchField}
                id="seachSupplier"
                variant="outlined"
                value={search}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        searchSuppliers();
                    }
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={searchSuppliers}>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <Button className={classes.newSupplierButton} onClick={() => setIsAddingNew(!isAddingNew)}>
                New supplier
            </Button>
            {items.length > 0 && <SuppliersTable items={items} />}
            <AddNewSupplier
                isVisible={isAddingNew}
                setIsVisible={setIsAddingNew}
                brands={brands}
                onCreateSuccess={searchSuppliers}
            />
        </div>
    );
}

Suppliers.propTypes = {
    classes: PropTypes.any
};

export default withStyles(supplierSyles)(Suppliers);
