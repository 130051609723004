import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    makeStyles,
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Switch,
    Grid
} from '@material-ui/core';
import { stockAllViewStyles as styles } from './styles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import EmailIcon from '@material-ui/icons/Email';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FilterSelect from '../common/FilterSelect';
import StockAllList from './StockAllList';
import stockApi from '../../api/stockApi';
import EditStockModal from './EditStockModal';
import DropdownButton from './DropdownButton/DropdownButton';
import exportCsv from '../../collums-components/helpers/exportCSV';
import currentStockColumns from './currentStockColumns';
import { toLocaleString, useDebounce } from '../../collums-components/helpers/index';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

import { toastr } from 'react-redux-toastr';
import { getClinic } from '../../collums-components/api/ClinicApi';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';
import _ from 'lodash';

function StockAllView({ type, classes }) {
    const [data, setData] = useState({});
    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 25,
        order: 'name'
    });
    const [normalStock, setNormalStock] = useState(true);
    const [lowStock, setLowStock] = useState(true);
    const [outOfStock, setOutOfStock] = useState(true);
    const [search, setSearch] = useState('');
    const [suppliersFilter, setSuppliersFilter] = useState([]);
    const [brandsFilter, setBrandsFilter] = useState([]);
    const [categoriesFilter, setCategoriesFilter] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [editStockProduct, setEditStockProduct] = useState(null);
    const [incTaxFilter, setIncTaxFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const debounceFilter = useDebounce(search, 1000);

    const [currentClinic, setCurrentClinic] = useState({});
    const currentLocation = localStorage.getItem(CURRENT_CLINIC);
    const globalInputSearchStyles = makeStyles(inputSearchStyles)();

    useEffect(() => {
        setFilter((filters) => {
            return {
                ...filters,
                type
            };
        });
        const getCurrentClinic = async () => {
            setCurrentClinic(await getClinic(currentLocation));
            return;
        };
        getCurrentClinic();
    }, [type]); //eslint-disable-line

    useEffect(() => {
        stockApi.getAllSuppliers().then(setSuppliersFilter);
        stockApi.getAllBrands().then(setBrandsFilter);
        stockApi.listProductCategories().then(setCategoriesFilter);
    }, []);

    useEffect(() => {
        const getStocks = async () => {
            try {
                setIsLoading(true);
                if (filter.type === 'All') delete filter.type;
                filter.source = 'stock';
                const query = {
                    ...filter,
                    name: debounceFilter
                };
                if (query.name === '') delete query.name;
                await stockApi.getStocks(query, currentClinic?.id).then(response => {
                    if ((query.name ? query.name.toString() : '') === search) {
                        setData(response)
                    }
                });
            } catch (error) {
                toastr.error(error?.data?.message || 'Something went wrong');
            } finally {
                setIsLoading(false);
            }
        };
        getStocks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, debounceFilter]);

    useEffect(() => {
        if (editStockProduct) return;
        const query = { ...filter };

        if (!_.isEmpty(search)) {
            query.name = search;
        }

        stockApi.getStocks(query, currentClinic?.id).then(response => {
            if ((query.name ? query.name.toString() : '') === search) {
                setData(response)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editStockProduct, setEditStockProduct]);

    // function to get data to csv
    const getFormattedData = async () => {
        const stock = await stockApi.getStocks(
            { ...filter, page: 0, pageSize: Number.MAX_SAFE_INTEGER },
            currentClinic?.id
        );

        if (!stock.meta) return;

        const totalObject = {};

        Object.keys(stock.data[0]).map((field) => {
            if (field === 'supplier') {
                totalObject[field] = 'Total';
            } else if (field === 'stock') {
                totalObject[field] = stock.meta.totals.stock;
            } else if (field === 'grossValue') {
                totalObject[field] = incTaxFilter ? stock.meta.totals.grossValue : stock.meta.totals.netValue;
            } else {
                totalObject[field] = '';
            }
            return totalObject;
        });

        const _stock = [totalObject, ...stock.data];

        const data = _stock.map((row, index) => {
            const formattedValues = Object.keys(row).map((name) => {
                if (row[name])
                    if (typeof row[name] === 'object') {
                        return row[name].name;
                    }
                if (typeof row[name] !== 'number') {
                    return row[name];
                }

                const column = currentStockColumns.find((col) => col.field === name);

                if (!column) return toLocaleString(row[name]);

                if (column.field === 'grossValue' && index !== 0) {
                    if (incTaxFilter) {
                        return toLocaleString(row[name]);
                    } else {
                        return toLocaleString(row['netValue']);
                    }
                }

                if (column.type === 'currency') return toLocaleString(row[name]);

                return row[name];
            });
            const newRow = { ...row };
            Object.keys(newRow).forEach((name, index) => {
                newRow[name] = formattedValues[index];
            });
            return newRow;
        });

        return data;
    };

    const getFiltersToCsv = () => {
        let filterObj = {};
        if (filter.brand) {
            filterObj.brand = brandsFilter.find((brand) => brand.id === filter.brand).name;
        }
        if (filter.category) {
            filterObj.category = categoriesFilter.find((category) => category.id === filter.category).name;
        }
        if (filter.supplier) {
            filterObj.supplier = suppliersFilter.find((supplier) => supplier.id === filter.supplier).name;
        }
        if (search.length > 0) {
            filterObj.search = search;
        }
        return filterObj;
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Box display="flex" flexDirection="row" marginBottom="8px" marginTop="8px">
                <DropdownButton
                    label={'Options'}
                    options={[
                        {
                            label: 'Export as CSV',
                            handleClick: async () => {
                                exportCsv({
                                    data: await getFormattedData(),
                                    tableInfo: {
                                        title: `${currentClinic.name} current stock`,
                                        filters: getFiltersToCsv()
                                    },
                                    columns: currentStockColumns,
                                    type: 'currentStock'
                                });
                            }
                        }
                    ]}
                />
                <Box marginLeft="35%" style={type !== 'All' ? { marginLeft: '32.5%' } : { marginLeft: '35%' }}>
                    <Typography className={classes.title}>{`${currentClinic.accountName} current Stock${
                        type === 'All' ? '' : ' - ' + type
                    }`}</Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" marginBottom="16px" justifyContent="space-between">
                <TextField
                    label="Search product"
                    variant="outlined"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    className={globalInputSearchStyles.inputSearch}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton
                                    className={globalInputSearchStyles.icons}
                                    onClick={() => setSearch('')}
                                    disabled={!search}
                                >
                                    <ClearIcon
                                        style={!search ? { color: 'transparent', fontSize: 24 } : { fontSize: 24 }}
                                    />
                                </IconButton>
                                <IconButton position="end" className={globalInputSearchStyles.icons}>
                                    <SearchIcon style={{ fontSize: 24 }} />
                                </IconButton>
                            </>
                        )
                    }}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={incTaxFilter}
                            onChange={({ target }) => {
                                setIncTaxFilter(target.checked);
                            }}
                        />
                    }
                    label="Ex Tax / Inc Tax"
                />
            </Box>
            <Box className={classes.lowerGroup}>
                <div className={classes.inputFilters}>
                    <div className={classes.marginRight8px}>
                        <FilterSelect
                            data={{ type: 'Supplier', values: suppliersFilter }}
                            setFilter={(params) => setFilter({ ...filter, supplier: params || undefined, page: 0 })}
                        />
                    </div>
                    <div className={classes.marginRight8px}>
                        <FilterSelect
                            data={{ type: 'Brand', values: brandsFilter }}
                            setFilter={(params) => setFilter({ ...filter, brand: params || undefined, page: 0 })}
                        />
                    </div>
                    <div className={classes.marginRight8px}>
                        <FilterSelect
                            data={{ type: 'Category', values: categoriesFilter }}
                            setFilter={(params) => setFilter({ ...filter, category: params || undefined, page: 0 })}
                        />
                    </div>
                </div>
                <div className={classes.checkboxGroup}>
                    <Grid container>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="normalStock"
                                        checked={normalStock}
                                        onChange={(event) => setNormalStock(event.target.checked)}
                                    />
                                }
                                label="Normal stock"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="lowStock"
                                        checked={lowStock}
                                        onChange={(event) => setLowStock(event.target.checked)}
                                    />
                                }
                                label="Low stock"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="outStock"
                                        checked={outOfStock}
                                        onChange={(event) => setOutOfStock(event.target.checked)}
                                    />
                                }
                                label="Out of stock"
                            />
                        </Grid>
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="showArchived"
                                        onChange={(event) =>
                                            setFilter({ ...filter, showArchived: event.target.checked, page: 0 })
                                        }
                                    />
                                }
                                label="Show archived"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Box>

            <Box>
                <StockAllList
                    currentLocation={currentLocation}
                    normalStock={normalStock}
                    lowStock={lowStock}
                    outOfStock={outOfStock}
                    tableData={data}
                    filter={filter}
                    setFilter={setFilter}
                    incTaxFilter={incTaxFilter}
                    setEditStockProduct={setEditStockProduct}
                    suppliersFilter={suppliersFilter}
                    brandsFilter={brandsFilter}
                    categoriesFilter={categoriesFilter}
                />
            </Box>
            <Dialog open={showEmailModal} onClose={() => setShowEmailModal(false)} className={classes.modalEmail}>
                <DialogTitle>
                    <Typography align="center" variant="h4">
                        Email stock list
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography align="center">Sent as csv file</Typography>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        className={classes.marginTop16px}
                        variant="outlined"
                        label="Enter email addresses separated by commas"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEmailModal(false)} color="primary" className={classes.cancelBtn}>
                        Cancel
                    </Button>
                    <Button className={classes.nextBtn} onClick={() => {}} color="primary">
                        Email list
                    </Button>
                </DialogActions>
            </Dialog>
            {!!editStockProduct && (
                <EditStockModal
                    onClose={() => setEditStockProduct(null)}
                    clinic={currentLocation}
                    product={editStockProduct}
                />
            )}
        </>
    );
}

StockAllView.propTypes = {
    type: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StockAllView);
