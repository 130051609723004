import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableRow, TableCell, Link, withStyles } from '@material-ui/core';
import StyledTableRow from '../common/StyledTableRow';
import { brandsTableStyles } from './styles';

function SuppliersTable({ classes, items }) {
    const getFullAddress = (item) => {
        const array = [item.address, item.address2, item.city, item.county, item.postCode].filter((el) => el);
        const result = array.join('-');
        return result;
    };
    return (
        <Table className={classes.root}>
            <TableHead>
                <TableRow>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Brands</TableCell>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Telephone</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item, index) => (
                    <StyledTableRow key={item.id || index}>
                        <TableCell>
                            <Link href={`/suppliers/${item.id}`} key={item.id} className={classes.link}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell className={classes.brands}>
                            {item.brands.map((brand, index) => (
                                <span key={index} className={classes.tableText}>
                                    {brand.name}
                                </span>
                            ))}
                        </TableCell>
                        <TableCell>
                            <span className={classes.tableText}>{item.contactName}</span>
                        </TableCell>
                        <TableCell>
                            <span className={classes.tableText}>{getFullAddress(item)}</span>
                        </TableCell>
                        <TableCell>
                            <Link href={`mailto:${item.email}`} className={classes.link}>
                                {item.email}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <span className={classes.tableText}>{item.telephone}</span>
                        </TableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}

SuppliersTable.propTypes = {
    items: PropTypes.array.isRequired,
    classes: PropTypes.any.isRequired
};

export default withStyles(brandsTableStyles)(SuppliersTable);
