export const supplierSyles = () => ({
    root: {
        padding: '1% 4%',
        display: 'flex',
        flexDirection: 'column'
    },
    saveButton: {
        width: 'min-content'
    },
    searchField: {
        width: '25%',
        margin: '12px 0',
        '& button': {
            padding: '4px'
        },
        '& input': {
            padding: '12.5px 14px'
        }
    },
    newSupplierButton: {
        backgroundColor: '#72c2f6',
        width: 'fit-content',
        borderRadius: '0',
        '&:hover': {
            backgroundColor: '#72c2f6 !important'
        },
        '& span': {
            color: 'white',
            textTransform: 'none',
            fontSize: '16px'
        }
    }
});

export const brandsTableStyles = () => ({
    root: {
        marginTop: '12px'
    },
    link: {
        display: 'block',
        fontSize: '12px',
        color: 'blue'
    },
    tableText: {
        fontSize: '12px',
        display: 'block'
    },
    brands: {
        fontSize: '12px'
    }
});

export const addNewSupplierStyles = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    inputContainer: {
        padding: '1vh 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& label': {
            fontFamily: 'Gilmer Medium, Roboto',
            fontSize: 14,
            width: '25%'
        },
        '& .MuiTextField-root': {
            width: '70%',
            '& input': {
                padding: '12px 20px'
            }
        }
    },
    form: {
        width: '85%',
        '& h4': {
            marginBottom: '2vh',
            textAlign: 'center'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    autocompleteContainer: {
        padding: '1vh 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& label': {
            fontFamily: 'Gilmer Medium, Roboto',
            fontSize: 14,
            width: '25%'
        },
        '& .MuiAutocomplete-root': {
            display: 'inline-flex',
            justifyContent: 'flex-end',
            '& .MuiTextField-root': {
                width: '87%',
                '& input': {
                    padding: '12px 20px'
                }
            }
        }
    }
});
