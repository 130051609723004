import { Box, IconButton, Table, TableBody, TableCell, TablePagination, TableRow, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { styles } from './styles';
import { toLocaleString } from '../../collums-components/helpers';

const OrderList = ({
    classes,
    productOrderList,
    calcNetPrice,
    calcTax,
    calcGrossPrice,
    renderTableHeader,
    handleDeleteRow,
    canEdit = true
}) => {
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const items = productOrderList.length;
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <Table className={classes.table} size="medium">
                {renderTableHeader()}
                <TableBody>
                    {productOrderList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow
                            key={'newProduct'}
                            style={{ backgroundColor: index % 2 ? '#c2c2c2' : 'white' }}
                            className={`${classes.tableRow}`}
                        >
                            {canEdit ? (
                                <>
                                    <TableCell className={`${classes.tableBodyCell}`}>{row.name || '-'}</TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        {row.brand ? row.brand.name : '-'}
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        {row.brand ? row.brand.name : '-'}
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>{row.name || '-'}</TableCell>
                                </>
                            )}

                            <TableCell className={`${classes.tableBodyCell}`}>{row.vol || '-'}</TableCell>
                            {canEdit && (
                                <>
                                    <TableCell className={`${classes.tableBodyCell}`}>{row.stock || '-'}</TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        {row.usedSinceLastOrder || '-'}
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>
                                        {row.lastOrderQty || '-'}
                                    </TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>{row.min || '-'}</TableCell>
                                    <TableCell className={`${classes.tableBodyCell}`}>{row.max || '-'}</TableCell>
                                </>
                            )}
                            <TableCell width={100} className={`${classes.tableBodyCell}`}>
                                {row.orderQuantity || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(row.costPrice) || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(calcNetPrice(row.netPrice, row.orderQuantity)) || '-'}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>{row.tax || '0%'}</TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(calcTax(row.netPrice, row.orderQuantity, row.tax)) ||
                                    toLocaleString('0')}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>
                                {toLocaleString(calcGrossPrice(row.netPrice, row.orderQuantity, row.tax)) ||
                                    toLocaleString('0')}
                            </TableCell>
                            <TableCell className={`${classes.tableBodyCell}`}>{row.notes || ''}</TableCell>
                            {canEdit && (
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteRow(row)} color="primary">
                                        <ClearIcon />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {canEdit && (
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={items}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    classes={{ root: classes.tablePagination }}
                />
            )}
        </Box>
    );
};

OrderList.propTypes = {
    classes: PropTypes.object.isRequired,
    productOrderList: PropTypes.array.isRequired,
    calcNetPrice: PropTypes.func.isRequired,
    calcTax: PropTypes.func.isRequired,
    calcGrossPrice: PropTypes.func.isRequired,
    renderTableHeader: PropTypes.func.isRequired,
    handleDeleteRow: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

export default withStyles(styles)(OrderList);
