export const styles = () => ({
    title: {
        fontSize: '24px',
        marginBottom: '1%'
    },
    table: {
        marginTop: 16,
        border: 'black solid',
        '& .MuiTableCell-root': {
            padding: '0.5vw'
        },
        '& .MuiTableBody-root .Mui-selected': {
            backgroundColor: '#F6BDBE'
        }
    },
    tableHeader: {
        fontSize: 12,
        opacity: 1,
        fontWeight: 'bold',
        borderColor: 'black',
        borderWidth: 2
    },
    tableRow: {
        cursor: 'pointer'
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 12,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        }
    },
    marginRight8px: {
        marginRight: 8
    }
});
