import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filterSelectStyles as styles } from './styles.js';
import { Select, MenuItem, InputLabel, FormControl, withStyles } from '@material-ui/core';

function FilterSelect({ data, classes, setFilter }) {
    const [selectedValue, setSelectedValue] = useState('');
    const handleChangeSelected = (event) => {
        setFilter(event.target.value);
        setSelectedValue(event.target.value);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="filter-select-label">{data.type}</InputLabel>
            <Select
                labelId="filter-select-label"
                id="filter-select"
                value={selectedValue}
                onChange={handleChangeSelected}
                label={data.type}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {data.values.map((element, index) => {
                    return (
                        <MenuItem key={`menu-item-${index}`} value={element.id}>
                            {element.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

FilterSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    setFilter: PropTypes.func
};

export default withStyles(styles)(FilterSelect);
