const columns = [
    { field: 'supplier', order: 'supplier', title: 'Supplier', reordable: true, tableType: 'UPDATE' },
    { field: 'brand', order: 'brand', title: 'Brand', reordable: true, tableType: 'ALL' },
    { field: 'category', order: 'category', title: 'Category', reordable: true, tableType: 'ALL' },
    { field: 'name', order: 'name', title: 'Name', reordable: true, tableType: 'ALL' },
    { field: 'vol', order: 'vol', title: 'Vol', reordable: true, type: 'number', tableType: 'ALL' },
    {
        field: 'grossPrice',
        order: 'grossPrice',
        title: 'Retail Price',
        reordable: true,
        type: 'currency',
        tableType: 'ALL'
    },
    {
        field: 'stock',
        order: 'stock',
        title: 'Expected',
        reordable: true,
        type: 'number',
        tableType: 'ALL'
    },
    /* {
        field: 'expStock',
        order: 'expStock',
        title: 'Expected Stock',
        reordable: true,
        type: 'number',
        tableType: 'UPDATE'
    },*/
    { field: 'count', order: 'count', title: 'Count', reordable: true, type: 'number', tableType: 'ALL' },
    {
        field: 'discrepancy',
        order: 'discrepancy',
        title: 'Discrepancy',
        reordable: false,
        type: 'number',
        tableType: 'ALL'
    }
];

export default columns;
