import {
    Dialog,
    DialogTitle,
    Box,
    DialogContent,
    Button,
    TextField,
    FormControl,
    Typography,
    IconButton,
    makeStyles,
    withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toastr } from 'react-redux-toastr';
import StockTakeApi from '../../api/stockTakeApi';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { stockTakeModalStyles } from './styles';

function StockTakeModal({
    stockTake = {},
    brands = [],
    categories = [],
    refreshList,
    type = 'new',
    onClose,
    clinic,
    classes
}) {
    const productType = ['All', 'Retail', 'Professional', 'Prescription'];
    let email = useSelector((state) => (state.auth.currentUser ? state.auth.currentUser.email.address : undefined));
    const globalButtonStyles = makeStyles(modalsButtonStyles)();
    const history = useHistory();

    const ArchiveStockTake = () => {
        const archiveAction = async () => {
            try {
                await StockTakeApi.archive(stockTake.id);
                /*toastr.success(
                    'Success',
                    `Stock take ${stockTake.name} successfuly ${stockTake.archived ? 'unarchived' : 'archived'}!`
                );*/
            } catch (err) {
                //toastr.error('Error', 'Something went wrong');
            }
            onClose();
            refreshList();
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Archive Stock Take</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>
                        Are you sure you want to {stockTake.archived ? 'unarchive' : 'archive'}?
                    </Typography>
                    <div className={globalButtonStyles.buttonsContainer}>
                        <Button className={globalButtonStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={globalButtonStyles.confirmButton} onClick={archiveAction}>
                            {stockTake.archived ? 'Unarchive' : 'Archive'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const DeleteStockTake = () => {
        const deleteAction = async () => {
            try {
                await StockTakeApi.deleteStockTake(stockTake.id);
                toastr.success('Success', `Stock take ${stockTake.name} successfuly deleted!`);
            } catch (err) {
                toastr.error('Error', 'Something went wrong');
            }
            onClose();
            refreshList();
        };

        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Delete Stock Take</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>Are you sure you want to delete?</Typography>
                    <div className={globalButtonStyles.buttonsContainer}>
                        <Button className={globalButtonStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={globalButtonStyles.confirmButton} onClick={deleteAction}>
                            Delete
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const NewStockTake = () => {
        const [name, setName] = useState('');
        const [selectedBrands, setSelectedBrands] = useState([]);
        const [selectedProductType, setProductType] = useState(undefined);
        const [selectedCategories, setSelectedCategories] = useState([]);

        const createAction = async () => {
            if (!name && !selectedProductType) {
                //toastr.error('Missing Field', 'Name and Product Type fields are required');
                return;
            }
            if (!name) {
                //toastr.error('Missing Field', 'Name field is required');
                return;
            }
            if (!selectedProductType) {
                //toastr.error('Missing Field', 'Product Type field is required');
                return;
            }
            try {
                const created = await StockTakeApi.create({
                    createdAt: new Date(),
                    name: name,
                    brand: selectedBrands.map((brand) => brand.id),
                    category: selectedCategories.map((category) => category.id),
                    performedBy: email,
                    productType: selectedProductType,
                    clinic: clinic
                });
                onClose();
                history.push(`/stock-take/${created._id}?clinic=${clinic}`);
            } catch (err) {
                console.error(err);
                toastr.error(err.data?.message || err.response?.data?.message || err.message);
                return;
            }
        };

        return (
            <Dialog open fullWidth maxWidth="sm">
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>New Stock Take</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box p={2}>
                        <form style={{ display: 'block' }}>
                            <FormControl style={{ width: '100%', marginBottom: 5 }} variant="outlined">
                                <div style={{ display: 'flex' }}>
                                    <label style={{ width: '30%' }}>Name:</label>
                                    <TextField
                                        id="name"
                                        style={{ width: '70%' }}
                                        value={name}
                                        variant="outlined"
                                        onChange={(evt) => {
                                            setName(evt.target.value);
                                        }}
                                    />
                                </div>
                            </FormControl>
                            <FormControl style={{ width: '100%', margin: '5px 0' }} variant="outlined">
                                <div style={{ display: 'flex' }}>
                                    <label style={{ width: '30%' }}>Product type:</label>
                                    <Autocomplete
                                        style={{ width: '70%' }}
                                        options={productType}
                                        className={classes.autocomplete}
                                        onChange={(e, value) => setProductType(value)}
                                        getOptionLabel={(option) => `${option}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                className={classes.autocompleteTextField}
                                            />
                                        )}
                                    />
                                </div>
                            </FormControl>
                            <FormControl style={{ width: '100%', margin: '5px 0' }} variant="outlined">
                                <div style={{ display: 'flex' }}>
                                    <label style={{ width: '30%' }}>Product categories:</label>
                                    <Autocomplete
                                        multiple
                                        className={classes.autocomplete}
                                        style={{ width: '70%' }}
                                        options={categories ? [{ name: 'All' }, ...categories] : []}
                                        value={selectedCategories}
                                        onChange={(e, value) => {
                                            if (value.some((category) => category.name === 'All'))
                                                setSelectedCategories(categories);
                                            else setSelectedCategories(value);
                                        }}
                                        getOptionLabel={(option) => `${option.name}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                className={classes.autocompleteTextField}
                                            />
                                        )}
                                    />
                                </div>
                            </FormControl>
                            <FormControl style={{ width: '100%', margin: '5px 0' }} variant="outlined">
                                <div style={{ display: 'flex' }}>
                                    <label style={{ width: '30%' }}>Brands:</label>
                                    <Autocomplete
                                        multiple
                                        className={classes.autocomplete}
                                        style={{ width: '70%' }}
                                        options={brands ? [{ name: 'All' }, ...brands] : []}
                                        value={selectedBrands}
                                        onChange={(e, value) => {
                                            if (value.some((brand) => brand.name === 'All')) setSelectedBrands(brands);
                                            else setSelectedBrands(value);
                                        }}
                                        getOptionLabel={(option) => `${option.name}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                className={classes.autocompleteTextField}
                                            />
                                        )}
                                    />
                                </div>
                            </FormControl>
                            <div className={globalButtonStyles.buttonsContainer}>
                                <Button className={globalButtonStyles.cancelButton} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button className={globalButtonStyles.confirmButton} onClick={createAction}>
                                    Create
                                </Button>
                            </div>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    };
    switch (type) {
        case 'delete':
            return <DeleteStockTake />;
        case 'archive':
            return <ArchiveStockTake />;
        default:
            return <NewStockTake />;
    }
}

StockTakeModal.propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    brands: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    refreshList: PropTypes.func.isRequired,
    stockTake: PropTypes.object.isRequired,
    clinic: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(stockTakeModalStyles)(StockTakeModal);
