import {
    Dialog,
    DialogTitle,
    Box,
    DialogContent,
    Button,
    Typography,
    IconButton,
    TextareaAutosize
} from '@material-ui/core';
import PropTypes from 'prop-types';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PurchaseOrder from './purchaseOrderModal';
import { ORDER } from '../../collums-constants/index';
import { toLocaleString } from '../../collums-components/helpers';
import domToPdf from 'dom-to-pdf';
import Askinology from '../../assets/images/Askinology.jpg';
import Collums from '../../assets/images/collums.ico';

const confirmStyle = {
    backgroundColor: '#b41778',
    borderRadius: 0,
    color: 'white',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#912d6b'
    },
    padding: 10,
    fontSize: 12,
    marginLeft: 10
};

const cancelButton = {
    borderColor: '#575A64',
    borderWidth: 1,
    borderStyle: 'solid',
    textTransform: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
    },
    padding: 10,
    fontSize: 12
};

const closeButton = {
    position: 'absolute',
    right: 5,
    top: 0,
    color: 'grey',
    zIndex: 100
};

const pdfHeader = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: 20,
    borderBottom: '2px solid black'
};

let pdfTable = {
    width: '100%',
    border: '1px solid black',
    fontSize: 12,
    borderCollapse: 'collapse'
};

const pdfFooter = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 40
};

const pdfDiv = { padding: '3%' };

function NewOrdersModal({ products = [], type = '', clinic = {}, supplier = {}, onClose, now }) {
    const history = useHistory();

    const calcNetPrice = (netPrice, orderQuantity) => {
        return netPrice * orderQuantity;
    };

    const calcGrossPrice = (netPrice, orderQuantity, tax) => {
        const totalTax = calcTax(netPrice, orderQuantity, tax);
        return netPrice * orderQuantity + totalTax;
    };

    const calcTax = (netPrice, orderQuantity, tax) => {
        return ((tax * netPrice) / 100) * orderQuantity;
    };

    const CancelOrder = () => {
        const cancelAction = async () => {
            history.push('/orders');
            onClose();
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Cancel Order</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>Are you sure you want to cancel?</Typography>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={onClose}>
                            Back
                        </Button>
                        <Button style={confirmStyle} onClick={cancelAction}>
                            Cancel order
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const RowsHeaders = () => {
        return (
            <tr>
                <th>Brand</th>
                <th>Product Name</th>
                <th>Vol</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Net</th>
                <th>Tax %</th>
                <th>Tax</th>
                <th>Gross</th>
                <th>Notes</th>
            </tr>
        );
    };

    const RaiseOrder = () => {
        const [data, setData] = useState({});
        const [orderNote, setOrderNote] = useState('');
        const [openRaiseModal, setOpenRaiseModal] = useState(false);
        const [purchaseModalType, setPurchaseModalType] = useState('raise');
        let id = useSelector((state) => state.auth.currentUser.id);
        const raiseAction = async (modalName = 'raise') => {
            setPurchaseModalType(modalName);
            setOpenRaiseModal(true);
            setData({
                status: ORDER.STATUS.RAISED,
                products,
                orderNote,
                createdby: id,
                location: clinic.id,
                supplier: supplier.id
            });
        };
        if (products.length === 0) {
            onClose();
            return;
        }
        const RenderRow = ({ row }) => {
            return (
                <>
                    <tr className="orderTableData">
                        <td style={{ textAlign: 'center' }}>{row.brand ? row.brand.name : '-'}</td>
                        <td style={{ textAlign: 'center' }}>{row.name || '-'}</td>
                        <td style={{ textAlign: 'center' }}>{row.vol || '-'}</td>
                        <td style={{ textAlign: 'center' }}>{row.orderQuantity || '-'}</td>
                        <td style={{ textAlign: 'center' }}>{toLocaleString(row.costPrice) || '-'}</td>
                        <td style={{ textAlign: 'center' }}>
                            {toLocaleString(calcNetPrice(row.netPrice, row.orderQuantity)) || '-'}
                        </td>
                        <td style={{ textAlign: 'center' }}>{row.tax || '0%'}</td>
                        <td style={{ textAlign: 'center' }}>
                            {toLocaleString(calcTax(row.netPrice, row.orderQuantity, row.tax)) || toLocaleString('0')}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            {toLocaleString(calcGrossPrice(row.netPrice, row.orderQuantity, row.tax)) ||
                                toLocaleString('0')}
                        </td>
                        <td style={{ textAlign: 'center' }}>{row.notes || '-'}</td>
                    </tr>
                </>
            );
        };

        RenderRow.propTypes = {
            row: PropTypes.object.isRequired
        };

        return (
            <>
                <Dialog open fullWidth maxWidth="md">
                    <IconButton aria-label="close" style={closeButton} onClick={onClose} className="button">
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle>
                        <div style={{ display: 'flex', position: 'relative', width: '100%', backgroundColor: 'white' }}>
                            <IconButton
                                onClick={() => {
                                    const options = {
                                        filename: 'order.pdf'
                                    };
                                    const input = document.getElementById('pdfdiv');
                                    return domToPdf(input, options, () => {
                                        return;
                                    });
                                }}
                                className="button"
                            >
                                <PrintIcon />
                            </IconButton>
                            <IconButton>
                                <GetAppIcon />
                            </IconButton>
                            <IconButton /* onClick={() => raiseAction('email')} */>
                                <EmailIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div id="pdfdiv" style={pdfDiv}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={Collums} style={{ width: 60, height: 60 }} alt="Collums" />
                                <Typography
                                    variant="h3"
                                    style={{ fontSize: 16, textAlign: 'center', marginBottom: 20 }}
                                >
                                    Purchase Order
                                </Typography>
                                <img src={Askinology} style={{ width: 60, height: 60 }} alt="Askinology" />
                            </div>
                            <div>
                                <div style={pdfHeader} className="pdfHeader">
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>
                                            <Typography variant="h4" style={{ marginBottom: 5 }}>
                                                Bill to:
                                            </Typography>
                                            <Typography>{clinic.accountName}</Typography>
                                            <Typography>{clinic.address ? clinic.address.street : ''}</Typography>
                                            <Typography>{clinic.address ? clinic.address.county : ''}</Typography>
                                            <Typography>{clinic.address ? clinic.address.country : ''}</Typography>
                                            <Typography>{clinic.address ? clinic.address.postcode : ''}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="h4" style={{ margin: '5px 0' }}>
                                                Contact:
                                            </Typography>
                                            <Typography>{clinic.accountName}</Typography>
                                            <Typography>{clinic.publicPhone}</Typography>
                                            <Typography>{clinic.publicEmail}</Typography>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>
                                            <Typography variant="h4" style={{ marginBottom: 5 }}>
                                                Supplier:
                                            </Typography>
                                            <Typography>{supplier.name}</Typography>
                                            <Typography>{supplier.address}</Typography>
                                            <Typography>{supplier.county || ''}</Typography>
                                            <Typography>{supplier.country || ''}</Typography>
                                            <Typography>{supplier.postcode || ''}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="h4" style={{ margin: '5px 0' }}>
                                                Contact:
                                            </Typography>
                                            <Typography>{supplier.contactName}</Typography>
                                            <Typography>{supplier.telephone}</Typography>
                                            <Typography>{supplier.email}</Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography>Date: {`${now.getDate()}/${now.getMonth()}`}</Typography>
                                        <Typography>Payment terms: 30 days</Typography>
                                    </div>
                                </div>
                                <div className="pdfTables" style={{ margin: '40px 0' }}>
                                    {/* Retail rows */}
                                    {products.find((product) => product.type === 'Retail') && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                padding: '3%'
                                            }}
                                        >
                                            <Typography variant="h3">Retail</Typography>
                                            <div style={{ marginTop: 20 }}>
                                                <table style={pdfTable} className="orderTable">
                                                    <thead style={{ backgroundColor: '#919191' }}>
                                                        <RowsHeaders />
                                                    </thead>
                                                    <tbody>
                                                        {products
                                                            .filter((product) => product.type === 'Retail')
                                                            .map((row) => {
                                                                return <RenderRow key={row.id} row={row} />;
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {products.find((product) => product.type === 'Professional') && (
                                        <div
                                            style={{
                                                marginTop: 20,
                                                padding: '3%'
                                            }}
                                        >
                                            <Typography variant="h3">Professional</Typography>
                                            <div style={{ marginTop: 20 }}>
                                                <table style={pdfTable} className="orderTable">
                                                    <thead style={{ backgroundColor: '#919191' }}>
                                                        <RowsHeaders />
                                                    </thead>
                                                    <tbody>
                                                        {products
                                                            .filter((product) => product.type === 'Professional')
                                                            .map((row) => {
                                                                return <RenderRow key={row.id} row={row} />;
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="pdfFooter" style={pdfFooter}>
                                    <TextareaAutosize
                                        style={{ width: '70%', minHeight: 100, resize: 'vertical', height: 100 }}
                                        placeholder="Any notes e.g. Please deliver between 8am and 6pm."
                                        onChange={(evt) => setOrderNote(evt.target.value)}
                                    />
                                    <div style={{ marginLeft: '10%', width: '20%' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Sub Total:</Typography>
                                            <Typography>
                                                {toLocaleString(
                                                    products.reduce(
                                                        (counter, element) =>
                                                            counter +
                                                            calcNetPrice(element.netPrice, element.orderQuantity),
                                                        0
                                                    )
                                                )}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Tax:</Typography>
                                            <Typography>
                                                {toLocaleString(
                                                    products.reduce(
                                                        (counter, element) =>
                                                            counter +
                                                            calcTax(
                                                                element.netPrice,
                                                                element.orderQuantity,
                                                                element.tax
                                                            ),
                                                        0
                                                    )
                                                )}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Total:</Typography>
                                            <Typography>
                                                {toLocaleString(
                                                    products.reduce(
                                                        (counter, element) =>
                                                            counter +
                                                            calcGrossPrice(
                                                                element.netPrice,
                                                                element.orderQuantity,
                                                                element.tax
                                                            ),
                                                        0
                                                    )
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        marginTop: 40,
                                        pageBreakBefore: 'always',
                                        '@media print': 'pre, blockquote {page-break-inside: avoid;}'
                                    }}
                                >
                                    <Button
                                        className="button"
                                        style={{
                                            ...cancelButton,
                                            pageBreakBefore: 'always',
                                            '@media print': 'pre, blockquote {page-break-inside: avoid;}'
                                        }}
                                        onClick={onClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        style={confirmStyle}
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            raiseAction();
                                        }}
                                        className="button"
                                    >
                                        Raise order
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                {openRaiseModal && (
                    <PurchaseOrder
                        data={data}
                        onClose={() => setOpenRaiseModal(false)}
                        onCloseAll={() => {
                            setOpenRaiseModal(false);
                            onClose();
                        }}
                        modalType={purchaseModalType}
                    />
                )}
            </>
        );
    };

    switch (type) {
        case 'cancel':
            return <CancelOrder />;
        case 'raise':
            return <RaiseOrder />;
        default:
            return <></>;
    }
}

NewOrdersModal.propTypes = {
    products: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    clinic: PropTypes.object.isRequired,
    supplier: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    now: PropTypes.object.isRequired
};

export default NewOrdersModal;
