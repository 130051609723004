import Axios from './config';

class ClinicApi {
    static listClinics() {
        return Axios({
            method: 'GET',
            url: '/clinics'
        }).then((res) => res.data);
    }
}

export default ClinicApi;
