import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    Button,
    Menu,
    MenuItem
} from '@material-ui/core';
import Moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import invoiceApi from '../../../api/InvoiceApi';
import AuthApi from '../../../api/AuthApi';
import { useReactToPrint } from 'react-to-print';

import practitionerApi from '../../../api/PractitionerApi';
import { INVOICE_PAYMENT_STATUS, INVOICE_ORIGIN } from '../../../../collums-constants/index';
import { InvoiceModalStyles } from './styles';
import { getCurrencySymbol, toLocaleString } from '../../../helpers/index';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import apps from '../../../../config';
import { toastr } from 'react-redux-toastr';
import {
    roundTwoDecimals,
    getTotalsFromInvoiceItems,
    calculateTaxValue
} from '../../../../collums-constants/utils/index';
import LoadingScreen from '../loadingScreen';
import CancelContinueModal from '../CancelContinueModal';
import InvoiceApi from '../../../api/InvoiceApi';

const itemsHeader = [
    { name: 'Sold By' },
    { name: 'Item' },
    { name: '#' },
    { name: 'Discount', numeric: true },
    { name: 'Net Price', numeric: true },
    { name: 'Tax', numeric: true },
    { name: 'Total', numeric: true }
];

const changeLogHeader = [{ name: 'Date' }, { name: 'By' }, { name: 'Note' }];

const paymentsHeader = [
    { name: 'Date/Item' },
    { name: 'Taken By' },
    { name: 'Method' },
    { name: 'Amount', numeric: true }
];

const InvoiceModal = ({ classes, invoice, closeInvoiceModal, loadInvoice, source, listUpdate, openRefundModal }) => {
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceNote, setInvoiceNote] = useState('');
    const [newInvoiceNote, setNewInvoiceNote] = useState('');
    const [practitionerList, setPractitionerList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [newValues, setNewValues] = useState({});
    const [activePractitioners, setActivePractitioners] = useState([]);
    const [voidPopupOpen, setVoidPopupOpen] = useState(false);
    const [voidOsPopupOpen, setVoidOsPopupOpen] = useState(false);
    const [referenceInvoice, setReferenceInvoice] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [modifiedsLogModel, setModifiedsLogModel] = useState(false);
    const componentRef = useRef();
    const [myClinics, setMyClinics] = useState([]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const isFirstRender = useRef(true);

    const isRefundModalOpen = useSelector(state => state.invoice?.isInvoiceRefundModalOpen);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, []);

    useEffect(() => {
        // event called on refund modal close.
        if (!isFirstRender.current && !isRefundModalOpen) {
            reload(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefundModalOpen]);

    const reload = async (reset = false) => {
        if (!invoice.id) return;

        setIsLoading(true);
        const res = await invoiceApi.getInvoice(invoice.id, true, true);
        if (res?.referencedInvoice) {
            res.items = res.items.map(element => {
                const netPrice = element.netPrice ? element.netPrice * -1 : 0;
                const discount = Math.abs(element.discount);
                const refundedAmount = Math.abs(element.refundedAmount) * -1;
                return { ...element, netPrice, discount, refundedAmount };
            });
            setInvoiceData(res);
        } else {
            res.items = res.items.map(element => {
                const netPrice = element.netPrice ? element.netPrice : 0;
                const discount = (element.discount || 0) * -1;
                return { ...element, netPrice, discount };
            });
            setInvoiceData(res);
        }
        if (!reset) {
            setNewValues({});
            setCanEdit(false);
            setNewInvoiceNote('');
        }

        practitionerApi.query().then(res => setPractitionerList(res.items));
        practitionerApi.listActivePractitioners().then(res => setActivePractitioners(res));

        const currentUser = await AuthApi.getMe();
        await practitionerApi.listPractitionerClinics(currentUser.id).then(res => {
            setMyClinics(res);
        });
        setIsLoading(false);
    };

    useEffect(() => {
        if (!invoice.id) return;
        reload();
        // eslint-disable-next-line
    }, [invoice.id]);

    useEffect(() => {
        if (invoiceData) {
            setInvoiceNote(invoiceData.description);
            if (invoiceData.description !== invoiceNote && invoiceData.id) {
                const description = { description: invoiceNote };
                invoiceApi.updateInvoice(invoiceData.id, description);
            }
        }
        // eslint-disable-next-line
    }, [invoiceData]);

    // calculate data to use on invoice summary
    const { invoiceSubTotal, invoiceTotal, invoiceTax, invoiceDiscount } = useMemo(() => {
        return getTotalsFromInvoiceItems(
            invoiceData?.items,
            '',
            invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND
        );
    }, [invoiceData]);

    const paymentTotal = useMemo(() => {
        if (!invoiceData.payments) return;
        const payments = invoiceData.payments.map(payment => payment.amount);
        return payments.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }, [invoiceData.payments]);

    const handleClose = () => {
        if (!referenceInvoice) closeInvoiceModal();
        else {
            openInvoice(referenceInvoice);
            setReferenceInvoice(undefined);
        }
    };
    const handleCloseMenu = () => setAnchorEl(null);

    const openInvoice = invoice => {
        loadInvoice(invoice);
    };

    const handleUpdateVoid = async () => {
        const currentUser = await AuthApi.getMe();
        await invoiceApi.updateInvoiceStatus(invoice.id, INVOICE_PAYMENT_STATUS.VOID, currentUser.data);
        await invoiceApi.getInvoice(invoice.id, false, true).then(setInvoiceData);
        if (source === 'calendar') {
            listUpdate();
        }
    };

    const handleRefund = async () => {
        openRefundModal();
        handleClose();
    };

    const havePayments = () => {
        return (
            invoiceData.paymentStatus !== INVOICE_PAYMENT_STATUS.UNPAID &&
            invoiceData.paymentStatus !== INVOICE_PAYMENT_STATUS.VOID &&
            invoiceData.paymentStatus !== INVOICE_PAYMENT_STATUS.REFUND
        );
    };

    const updateInvoice = async () => {
        const invoiceId = newValues.id;
        const invoiceItems = newValues.items.map(item => {
            return { id: item.id, soldBy: item.soldBy?.id };
        });
        const haveUndefined = invoiceItems.find(el => el.soldBy === undefined);
        if (haveUndefined) {
            toastr.error('Sold by cannot be blank');
            return;
        }
        if (!newInvoiceNote) {
            toastr.error('Please complete invoice notes');
            return;
        }
        try {
            await invoiceApi.updateInvoiceItems(invoiceId, { invoiceItems, newInvoiceNote });
            toastr.success('Invoice successfuly updated');
            await reload();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const changeEdit = async () => {
        if (!canEdit) {
            await reload(true);
        }
        setNewValues(canEdit ? {} : invoiceData);
        setCanEdit(!canEdit);
        setNewInvoiceNote('');
    };

    // used when it's not on CC
    const getCustomerName = customer => {
        return (
            <a
                target={'_blank'}
                rel="noopener noreferrer"
                href={`${apps.calendarUrl}/customer/${customer?.id}/general`}
                style={{ color: '#0000EE', textDecoration: 'underline', cursor: 'pointer' }}
            >
                {customer?.firstName || ''} {customer?.surname || ''}
            </a>
        );
    };

    const colors = ['#1565C0', '#303F9F', '#512DA8', '#00695C'];

    const RenderShortNotes = () => {
        if (!invoiceData.modifies?.map) return <></>;

        const SeeMoreBtn = () => (
            <b style={{ color: '#15F' }} onClick={() => setModifiedsLogModel(true)}>
                ... see more
            </b>
        );

        function shortNote(note) {
            if (note.description.length < 60) return <>{note.description}</>;
            return (
                <span>
                    {note.description.substring(0, 60)} <SeeMoreBtn />
                </span>
            );
        }

        const mapShortNotes = invoiceData.modifies
            .map((note, i) => {
                const color = i >= colors.length ? colors[i % 4] : colors[i];

                return (
                    <Typography style={{ color }} key={i + '-note-key'}>
                        <b>{note.modifiedBy.displayName}</b>: {shortNote(note)}
                    </Typography>
                );
            })
            .reverse();
        return <Box>{mapShortNotes}</Box>;
    };

    const RenderLogTableRows = () => {
        let modifiesMap = invoiceData?.modifies || [];
        const logs = [...modifiesMap, ...(invoiceData?.invoiceLogs || [])];
        logs.sort((a, b) => {
            return new Date(a?.date) - new Date(b?.date);
        });

        return logs
            .map((change, i) => {
                return (
                    <TableRow key={i + '-log-table-key'}>
                        <TableCell className={classes.tableBodyCell}>
                            {Moment(change.date).format('DD/MM/YY HH:mm')}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                            {change?.modifiedBy?.displayName || change.practitioner?.displayName}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>{change.description}</TableCell>
                    </TableRow>
                );
            })
            .reverse();
    };

    const showVoidOutstanding = useMemo(() => {
        if (!invoiceData) return false;
        if (invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.PAID) return false;

        // == instead of ===  because the expression returns a float
        // eslint-disable-next-line
        if (Math.abs(invoiceTotal - paymentTotal).toFixed(2) == 0) return false; // no outstanding

        if (invoiceData.relatedInvoices && invoiceData.relatedInvoices.length > 0) {
            // if there is some related invoice with refund
            return invoiceData.relatedInvoices.some(relatedInvoice => relatedInvoice.origin === INVOICE_ORIGIN.REFUND);
        }
        return false;
    }, [invoiceData, invoiceTotal, paymentTotal]);

    const handleVoidOutstanding = async () => {
        try {
            await invoiceApi.voidOutstandingAmount(invoice.id);
            toastr.success('Amount successfuly voided');
            await reload();
            if (listUpdate) {
                listUpdate();
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                return toastr.error(err.response.data.message);
            }

            toastr.error('Something went wrong');
        }
    };

    const [voidPopupDisabledButtons, setVoidPopupDisabledButtons] = useState(false);

    const calcInvoiceItemValues = useCallback(
        element => {
            const { netPrice, discount, quantity, tax, soldBy, refundedAmount } = element;
            const taxValue = 1 + (element?.tax || 0);
            const taxLabel = (element?.tax ?? 0) * 100 + '%';
            const isRefunded = invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND;

            const elementValue = () => {
                if (isRefunded) return refundedAmount;
                return quantity * netPrice + discount / taxValue;
            };

            // calculate unitary tax, using partial discounts for quantity > 1
            const unitaryTax = calculateTaxValue({
                tax,
                netPrice: elementValue() / quantity,
                quantity: 1
            });

            // calculate total taxes, using total net price (with discounts)
            const totalTax = () => {
                if (isRefunded) return refundedAmount - refundedAmount / taxValue;
                return calculateTaxValue({
                    tax: tax,
                    netPrice: elementValue(),
                    quantity: 1
                });
            };
            // calculate total gross price first, then remove tax and discount
            const netPriceLabel = () => {
                if (isRefunded) {
                    return roundTwoDecimals(refundedAmount / taxValue);
                }
                return roundTwoDecimals(netPrice + unitaryTax) * quantity - totalTax() + discount / taxValue;
            };

            const elementTotal = () => {
                if (isRefunded) return refundedAmount;
                return (
                    element.quantity *
                        (calculateTaxValue({
                            tax: element.tax,
                            netPrice: element.netPrice,
                            quantity: 1
                        }) +
                            element.netPrice) +
                    element.discount
                );
            };

            const soldByLabel = soldBy ? element.soldBy?.displayName : '-';

            return {
                hasTax: taxValue !== 1,
                tax: taxLabel,
                totalTax: totalTax(),
                netPrice: netPriceLabel(),
                elementTotal: elementTotal(),
                soldBy: soldByLabel
            };
        },
        // eslint-disable-next-line
        [invoiceData.items]
    );

    const TableBodyTotalPrice = (element, elementTotal) => {
        if (element.netPrice !== 0) {
            if (`${Math.round(parseFloat(element.tax), 10)}%`) {
                return toLocaleString(elementTotal);
            } else {
                return `${getCurrencySymbol()}0.00`;
            }
        } else {
            return '-';
        }
    };

    const sendEmail = async () => {
        try {
            await InvoiceApi.sendEmailInvoice(invoiceData.id);
            toastr.success('Invoice successfuly sended');
            await reload();
        } catch (err) {
            toastr.error('Something went wrong');
        }
    };

    const hasRefundedInvoice = () => {
        if (invoiceData?.relatedInvoices?.length) {
            return !!invoiceData.relatedInvoices.find(
                ri => ri.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND
            )
        }

        return false;
    };

    const canRefundInvoice = () => {
        return havePayments() && !hasRefundedInvoice();
    };

    const canVoidInvoice = () => {
        return invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID;
    };

    return (
        <Dialog
            open={(source === 'calendar' ? invoice.isInvoiceModalOpen : true) && invoiceData && practitionerList}
            maxWidth="1000"
            className={classes.dialog}
            onBackdropClick={() => handleClose()}
            onEscapeKeyDown={() => handleClose()}
        >
            {isLoading && <LoadingScreen />}
            <div ref={componentRef} className="invoice">
                <DialogTitle align="center">
                    <Box width="100%" style={{ display: 'none' }} className="logo-container">
                        <img
                            alt="logo"
                            style={{ maxHeight: '200px', maxWidth: '100%' }}
                            src={invoiceData?.organization?.logo}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box display="flex" flexDirection="column" width="100%">
                            <span className={`${classes.fontSize18px} ${classes.title}`}>
                                {canEdit ? 'Edit' : ''} Invoice #{invoiceData.originalCode || invoiceData.code}
                            </span>
                            <span
                                className={
                                    [INVOICE_PAYMENT_STATUS.UNPAID, INVOICE_PAYMENT_STATUS.PART_PAID].includes(
                                        invoiceData?.paymentStatus
                                    ) && classes.fontRed
                                }
                            >
                                {invoiceData?.paymentStatus?.toUpperCase()}
                            </span>
                            <span>
                                {invoiceData?.referencedInvoice ? (
                                    <>
                                        Refund of original invoice{' '}
                                        <Button
                                            onClick={() => {
                                                setReferenceInvoice(invoiceData.id);
                                                openInvoice(invoiceData.referencedInvoice.id);
                                            }}
                                        >
                                            {invoiceData.referencedInvoice.originalCode ||
                                                invoiceData.referencedInvoice.code}
                                        </Button>
                                    </>
                                ) : (
                                    ''
                                )}
                            </span>
                        </Box>
                        <IconButton className={`${classes.closeButton} close-button`} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" justifyContent="space-between" className={classes.box}>
                        <Box display="flex" flexDirection="column">
                            <Typography className={classes.marginTop8px}>
                                Date: {Moment(invoiceData?.date).format('DD/MM/YYYY')}
                            </Typography>
                            <Typography className={classes.marginTop8px}>
                                Closed by: {invoiceData.closedBy && invoiceData.closedBy?.displayName}
                            </Typography>
                            {source !== 'calendar' && (
                                <Typography className={classes.marginTop8px}>
                                    Customer: {invoiceData.customerData && getCustomerName(invoiceData.customerData[0])}
                                </Typography>
                            )}
                        </Box>
                        <Box display="flex" alignItems="flex-end">
                            {source === 'calendar' && (
                                <div className="options-button">
                                    <Button
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        color="primary"
                                        className={classes.button}
                                        onClick={event => setAnchorEl(event.currentTarget)}
                                        disabled={myClinics.map(c => c.id).includes(invoiceData.clinic) === false}
                                    >
                                        Options
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                        className={classes.optionsMenu}
                                    >
                                        {(invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID ||
                                            invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID) && (
                                            <MenuItem
                                                onClick={() => {
                                                    window.open(
                                                        `${apps.posUrl}/sale?invoice=${invoiceData.id}`,
                                                        '_blank'
                                                    );
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Take Payment
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() => {
                                                changeEdit();
                                                handleCloseMenu();
                                            }}
                                        >
                                            {!canEdit ? 'Edit Invoice' : 'Cancel'}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                sendEmail();
                                                handleCloseMenu();
                                            }}
                                        >
                                            Email invoice
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setModifiedsLogModel(true);
                                                handleCloseMenu();
                                            }}
                                        >
                                            Invoice Log
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handlePrint();
                                                handleCloseMenu();
                                            }}
                                        >
                                            Print
                                        </MenuItem>
                                        {showVoidOutstanding && (
                                            <MenuItem
                                                onClick={() => {
                                                    setVoidOsPopupOpen(true);
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Void OS amount
                                            </MenuItem>
                                        )}
                                        {canRefundInvoice() && (
                                            <MenuItem
                                                className={classes.fontRed}
                                                onClick={() => {
                                                    handleRefund();
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Refund
                                            </MenuItem>
                                        )}
                                        {canVoidInvoice() && (
                                            <MenuItem
                                                className={classes.fontRed}
                                                onClick={() => {
                                                    setVoidPopupOpen(true);
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Void
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Typography className={classes.boldFont}>Invoice Items</Typography>
                        <Table className={classes.table} size="medium" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {itemsHeader.map((element, index) => {
                                        return (
                                            <TableCell
                                                key={`${index}-items-header`}
                                                className={classes.tableHeader}
                                                align={element.numeric ? 'right' : 'left'}
                                            >
                                                {element.name}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData.items?.map((element, index) => {
                                    const { totalTax, netPrice, soldBy, elementTotal } = calcInvoiceItemValues(element);
                                    return (
                                        <TableRow key={`${index}-item-table`} className={classes.tableRow}>
                                            <TableCell className={classes.tableBodyCell}>
                                                {canEdit ? (
                                                    <>
                                                        <Autocomplete
                                                            id="soldBy-autocomplete"
                                                            options={activePractitioners}
                                                            defaultValue={element.soldBy ? element.soldBy : null}
                                                            getOptionLabel={option => option.displayName}
                                                            style={{ width: 200 }}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label=""
                                                                    variant="outlined"
                                                                    className={classes.staffSelection}
                                                                />
                                                            )}
                                                            onChange={(evt, newSoldBy) => {
                                                                const updatedInvoice = newValues;
                                                                updatedInvoice.items[index].soldBy = newSoldBy;
                                                                setNewValues(updatedInvoice);
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    soldBy
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {element.name}
                                                {element.isRedemption && (
                                                    <>
                                                        <br />
                                                        <b>- Redeemed</b>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.quantity}
                                            </TableCell>

                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.discount !== 0 ? toLocaleString(element.discount || 0) : '-'}
                                            </TableCell>

                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.netPrice !== 0 ? toLocaleString(netPrice) : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.netPrice === 0 ? '-' : toLocaleString(totalTax)}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {TableBodyTotalPrice(element, elementTotal)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box mt={2}>
                        <Typography className={classes.boldFont}>Invoice notes:</Typography>
                        <Box ml={1}>{RenderShortNotes()}</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                        {canEdit && (
                            <Box width="50%" height="100%">
                                <TextField
                                    multiline
                                    label="New note"
                                    value={newInvoiceNote}
                                    className={classes.notesInput}
                                    rows={4}
                                    onChange={e => setNewInvoiceNote(e.target.value)}
                                    variant="outlined"
                                />
                            </Box>
                        )}
                        <Box>
                            <Typography>{invoiceData.temporaryDiscount?.name || ''}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" textAlign="right" className={classes.invoiceDetails}>
                            <Typography>
                                Net Total:
                                <span>{toLocaleString(invoiceSubTotal)}</span>
                            </Typography>
                            <Typography className={classes.marginTop8px}>
                                Tax:
                                <span>{toLocaleString(invoiceTax)}</span>
                            </Typography>
                            <Typography className={`${classes.boldFont} ${classes.marginTop8px}`}>
                                Grand Total:
                                <span>{toLocaleString(parseFloat(invoiceTotal.toFixed(2)))}</span>
                            </Typography>
                            {invoiceDiscount !== 0 && (
                                <Typography className={classes.marginTop8px}>
                                    Inc Gross Discount:
                                    <span>{toLocaleString(invoiceDiscount)}</span>
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    {canEdit && (
                        <Box mt={1} mb={3}>
                            <Button className={classes.cancelBtn} onClick={changeEdit}>
                                Cancel
                            </Button>
                            <Button className={classes.button} onClick={updateInvoice}>
                                Save
                            </Button>
                        </Box>
                    )}
                    <Box mt={canEdit ? 3 : 5}>
                        <Box>
                            <Typography className={classes.boldFont}>Payment</Typography>
                        </Box>
                        <Table className={classes.table} size="medium" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {paymentsHeader.map((element, index) => {
                                        return (
                                            <TableCell
                                                key={`${index}-payments-header`}
                                                className={classes.tableHeader}
                                                align={element.numeric ? 'right' : 'left'}
                                            >
                                                {element.name}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData.payments?.map((element, index) => {
                                    return (
                                        <TableRow key={`${index}-payment-table`} className={classes.tableRow}>
                                            <TableCell className={classes.tableBodyCell}>
                                                {Moment(element.createdAt).format('DD/MM/YYYY HH:mm')}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {element.takenBy?.displayName}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {element.squarePaymentId ? (
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={`https://${
                                                            process.env.REACT_APP_ENVIRONMENT === 'production'
                                                                ? 'squareup'
                                                                : 'squareupsandbox'
                                                        }.com/dashboard/sales/transactions/${element.squarePaymentId}`}
                                                        style={{ color: '#0000EE', textDecoration: 'underline' }}
                                                    >
                                                        {element.method}{' '}
                                                        {element.cardBrand &&
                                                            `${element.cardBrand} ${element.cardLast4}`}
                                                    </a>
                                                ) : (
                                                    <span>
                                                        {`${element.method} ${
                                                            element.giftcardId ? '- ' + element.giftcardId.code : ''
                                                        }`}{' '}
                                                        {element.cardBrand &&
                                                            `${element.cardBrand} ${element.cardLast4}`}
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {toLocaleString(element.amount)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Box mt={2} display="flex" flexDirection="column" alignItems="flex-end" mb={1.5}>
                            <Typography className={classes.boldFont}>Total: {toLocaleString(paymentTotal)}</Typography>
                            {invoiceData?.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID && (
                                <Typography className={`${classes.boldFont} ${classes.fontRed}`}>
                                    OUTSTANDING: {toLocaleString(invoiceTotal - paymentTotal)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </DialogContent>
            </div>
            <CancelContinueModal
                open={voidPopupOpen}
                title="Void Invoice"
                contentHtml={
                    '<p>Are you sure you want to void this invoice and associated payments?</p><br /><p>If you void this invoice, any account items it contains will be removed from the client’s account</p>'
                }
                onContinue={() => {
                    setVoidPopupDisabledButtons(true);
                    handleUpdateVoid().then(() => {
                        closeInvoiceModal();
                        setVoidPopupDisabledButtons(false);
                        setVoidPopupOpen(false);
                        handleCloseMenu();
                    });
                }}
                onCancel={() => {
                    setVoidPopupOpen(false);
                    setVoidPopupDisabledButtons(false);
                }}
                closeOnBlur
                disableButtons={voidPopupDisabledButtons}
            />
            <CancelContinueModal
                open={voidOsPopupOpen}
                title="Void Invoice"
                contentHtml={'<p>Are you sure you want to void OS amount?</p>'}
                onContinue={() => {
                    handleVoidOutstanding().then(() => {
                        closeInvoiceModal();
                        setVoidOsPopupOpen(false);
                        handleCloseMenu();
                    });
                }}
                cancelButtonText={'Back'}
                continueButtonText={'Void OS amount'}
                onCancel={() => {
                    setVoidOsPopupOpen(false);
                    handleCloseMenu();
                }}
                closeOnBlur
            />
            <Dialog
                open={modifiedsLogModel}
                className={classes.dialog}
                onBackdropClick={() => setModifiedsLogModel(false)}
                onEscapeKeyDown={() => setModifiedsLogModel(false)}
            >
                <Box minWidth={500} minHeight={500}>
                    <DialogTitle>
                        <Typography align="center">
                            <b className={`${classes.fontSize18px} ${classes.title}`}>Invoice Log</b>
                        </Typography>
                    </DialogTitle>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {changeLogHeader.map((head, i) => {
                                    return (
                                        <TableCell className={classes.tableHeader} key={i}>
                                            {head.name}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>{RenderLogTableRows()}</TableBody>
                    </Table>
                </Box>
                <IconButton className={classes.closeButton} onClick={() => setModifiedsLogModel(false)}>
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </Dialog>
    );
};

InvoiceModal.propTypes = {
    classes: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    closeInvoiceModal: PropTypes.func.isRequired,
    loadInvoice: PropTypes.func.isRequired,
    source: PropTypes.string,
    listUpdate: PropTypes.func,
    openRefundModal: PropTypes.func
};

export default withStyles(InvoiceModalStyles)(InvoiceModal);
