import Axios from './config';
import querystring from 'query-string';

const getClinicFromLocalStorage = () => {
    return localStorage.getItem('currentClinic');
};

class StockApi {
    static getStocks(filter, clinic = null) {
        return Axios({
            method: 'GET',
            url: `stocks?${querystring.stringify(filter)}&clinic=${clinic ? clinic : getClinicFromLocalStorage()}`
        }).then((res) => res.data);
    }

    static getAllSuppliers() {
        return Axios({
            method: 'GET',
            url: `stocks/suppliers?&clinic=${getClinicFromLocalStorage()}`
        }).then((res) => res.data);
    }

    static getAllBrands(isFromOrg = false) {
        return Axios({
            method: 'GET',
            url: `stocks/brands?isFromOrg=${isFromOrg}&clinic=${getClinicFromLocalStorage()}`
        }).then((res) => res.data);
    }

    static updateStock(id, payload) {
        return Axios({
            method: 'POST',
            url: `/stocks/history/${id}`,
            data: payload
        }).then((res) => res.data);
    }

    static getProductStockHistory(productId) {
        return Axios({
            method: 'GET',
            url: `stocks/history/${productId}/${getClinicFromLocalStorage()}`
        }).then((res) => res.data);
    }

    static listProductCategories() {
        return Axios({
            method: 'GET',
            url: `/stocks/categories?withSubcategories=false&active=true&isFromOrg=false&clinic=${getClinicFromLocalStorage()}`
        }).then((res) => res.data);
    }
}

export default StockApi;
