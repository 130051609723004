const columns = [
    { field: 'supplier', order: 'supplier.name', title: 'Supplier', reordable: true },
    { field: 'brand', order: 'brand.name', title: 'Brand', reordable: true },
    { field: 'category', order: 'category.name', title: 'Category', reordable: true },
    { field: 'name', order: 'name', title: 'Product Name', reordable: true },
    { field: 'vol', order: 'vol', title: 'Vol', reordable: true },
    // { field: 'costPrice', order: 'costPrice', title: 'Cost Price', reordable: true },
    { field: 'grossPrice', order: 'grossPrice', title: 'Retail Price', reordable: true, type: 'currency' },
    { field: 'min', order: 'min', title: 'Min', reordable: true, type: 'number' },
    { field: 'max', order: 'max', title: 'Max', reordable: true, type: 'number' },
    { field: 'stock', order: 'stock', title: 'Stock', reordable: true, type: 'number' },
    // { field: 'costValue', order: 'costValue', title: 'Cost Value', reordable: true },
    { field: 'grossValue', order: 'grossValue', title: 'Retail Value', reordable: true, type: 'currency' }
];

export default columns;
