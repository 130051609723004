import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    TablePagination
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { stockAllListStyles as styles } from './styles';
import StockHistoryModal from './StockHistoryModal';
import { toLocaleString } from '../../collums-components/helpers/index';
import config from '../../config';
import headerTable from './currentStockColumns';
function StockAllList({
    classes,
    currentLocation,
    tableData,
    normalStock,
    outOfStock,
    lowStock,
    filter,
    setFilter,
    incTaxFilter,
    setEditStockProduct,
    suppliersFilter,
    brandsFilter,
    categoriesFilter
}) {
    const [displayHistoryModal, setDisplayHistoryModal] = useState(false);
    const [productHistory, setProductHistory] = useState(null);

    const getLocationItem = (item) => {
        return item.locations.find((item) => item.clinic === currentLocation);
    };
    const [sort, setSort] = useState({
        direction: filter.order.substr(0, 1) === '-' ? 'desc' : 'asc',
        active: filter.order
    });

    const getRetailValue = (row) => {
        if (row) {
            if (incTaxFilter) {
                return getLocationItem(row).grossPrice * row.stock;
            } else {
                return getLocationItem(row).netPrice * row.stock;
            }
        }

        return 0;
    };

    const handleSortFilter = (cellOrder) => {
        setFilter({ ...filter, page: 0, order: sort.direction === 'asc' ? `-${cellOrder}` : cellOrder });
        setSort({
            direction: sort.direction === 'asc' ? 'desc' : 'asc',
            active: cellOrder
        });
    };

    /* const handleSortedElements = () => {
        const sortedElements = orderBy(tableData.data, sort.active, sort.direction);
        return sortedElements;
    }; */

    const renderTableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {headerTable.map((cell) => {
                        return (
                            <TableCell
                                key={cell.field}
                                padding="default"
                                className={`${classes.tableHeader} ${
                                    cell.type && (cell.type === 'number' || cell.type === 'currency')
                                        ? classes.alignTextRight
                                        : ''
                                }`}
                                sortDirection={sort.direction}
                            >
                                {cell.reordable ? (
                                    <TableSortLabel
                                        direction={sort.direction}
                                        active={sort.active === cell.order}
                                        onClick={() => handleSortFilter(cell.order)}
                                    >
                                        <span>{cell.title}</span>
                                    </TableSortLabel>
                                ) : (
                                    <span>{cell.title}</span>
                                )}
                            </TableCell>
                        );
                    })}
                    <TableCell />
                </TableRow>
            </TableHead>
        );
    };

    const redirectToAdminProducts = (productId) => {
        window.open(
            `${config.adminUrl}/resources/location/${currentLocation}/products?productId=${productId}`,
            '_blank'
        );
    };

    const getRowColor = useCallback(
        (row) => {
            if (getLocationItem(row).archived) {
                return classes.archivedRow;
            } else if (row.autoStockControl) {
                if (row.stock <= 0) {
                    return classes.tableRowRed;
                } else if (row.stock <= row.min) {
                    return classes.tableRowOrange;
                }
            }
            return '';
        },
        [classes.archivedRow, classes.tableRowOrange, classes.tableRowRed] //eslint-disable-line
    );

    const totalValue = useMemo(() => {
        if (tableData.meta && tableData.meta.totals) {
            if (incTaxFilter) {
                return toLocaleString(tableData.meta.totals.grossValue);
            } else {
                return toLocaleString(tableData.meta.totals.netValue);
            }
        }
        return toLocaleString(0);
    }, [incTaxFilter, tableData.meta]);

    const getProductBrandName = (item) => {
        const locationItem = getLocationItem(item);

        if (locationItem?.brand) {
            const brand = (brandsFilter || []).find((el) => el?.id === locationItem?.brand);
            if (brand) {
                return brand.name;
            }
        }

        return '';
    };

    const getProductCategoryName = (item) => {
        const locationItem = getLocationItem(item);

        if (locationItem?.category) {
            const category = (categoriesFilter || []).find((el) => el?.id === locationItem?.category);
            if (category) {
                return category.name;
            }
        }

        return '';
    };

    const getSupplierName = (item) => {
        const locationItem = getLocationItem(item);

        if (locationItem?.brand) {
            const supplier = (suppliersFilter || []).find((el) => el?.id === locationItem?.supplier);
            if (supplier) {
                return supplier.name;
            }
        }

        return '';
    };

    return (
        <>
            <Table className={classes.table} size="medium" stickyHeader>
                {renderTableHeader()}
                <TableBody>
                    <TableRow className={classes.firstTableRow}>
                        <TableCell>
                            <span>Total</span>
                        </TableCell>
                        <TableCell
                            colSpan={8}
                            align="right"
                            className={`${classes.alignTextRight}  ${classes.paddingRight33px}`}
                        >
                            <span>{tableData.meta && tableData.meta.totals.stock}</span>
                        </TableCell>
                        <TableCell className={classes.alignTextRight}>
                            <span>{totalValue}</span>
                        </TableCell>
                    </TableRow>
                    {tableData.data &&
                        tableData.data
                            .filter((row) => {
                                if (!outOfStock) {
                                    return row.stock > 0;
                                }
                                return row;
                            })
                            .filter((row) => {
                                if (!lowStock) {
                                    return !(row.stock <= row.min && row.stock > 0);
                                }
                                return row;
                            })
                            .filter((row) => {
                                if (!normalStock) {
                                    return row.stock <= row.min;
                                }
                                return row;
                            })
                            .map((row) => {
                                return (
                                    <TableRow key={row.id} className={getRowColor(row)}>
                                        <TableCell className={classes.tableBodyCell}>
                                            <span>{getSupplierName(row)}</span>
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            <span>{getProductBrandName(row)}</span>
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            <span>{getProductCategoryName(row)}</span>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => redirectToAdminProducts(row.id)}
                                            className={`${classes.tableBodyCell} ${classes.rowClickable}`}
                                        >
                                            <span>{row.name}</span>
                                        </TableCell>
                                        <TableCell className={classes.tableBodyCell}>
                                            <div>
                                                <span>
                                                    {row.vol} {row.volUnit}
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.tableBodyCell} ${classes.alignTextRight} ${classes.paddingRight33px}`}
                                        >
                                            <span>{toLocaleString(getLocationItem(row).grossPrice || 0)}</span>
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.tableBodyCell} ${classes.alignTextRight} ${classes.paddingRight33px}`}
                                        >
                                            {getLocationItem(row).min}
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.tableBodyCell} ${classes.alignTextRight} ${classes.paddingRight33px}`}
                                        >
                                            {getLocationItem(row).max}
                                        </TableCell>
                                        {row.autoStockControl ? (
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${classes.rowClickable} ${classes.alignTextRight} ${classes.paddingRight33px}`}
                                                onClick={() =>
                                                    setEditStockProduct({
                                                        id: row.id,
                                                        name: row.name,
                                                        category: { name: row.category ? row.category.name : '' },
                                                        stock: row.stock
                                                    })
                                                }
                                            >
                                                <span>{row.stock}</span>
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${classes.alignTextRight} ${classes.paddingRight33px}`}
                                            >
                                                <span>NA</span>
                                            </TableCell>
                                        )}
                                        <TableCell className={`${classes.tableBodyCell} ${classes.alignTextRight}`}>
                                            {toLocaleString(getRetailValue(row))}
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.backgroundWhite} ${classes.rowClickable}`}
                                            onClick={() => {
                                                setDisplayHistoryModal(true);
                                                setProductHistory({ id: row.id, name: row.name });
                                            }}
                                        >
                                            <AccessTimeIcon />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={(tableData.meta && tableData.meta.totalOfItens) || 0}
                rowsPerPage={filter.pageSize}
                page={filter.page}
                onChangePage={(e, page) => setFilter({ ...filter, page })}
                onChangeRowsPerPage={(e) => setFilter({ ...filter, pageSize: e.target.value, page: 0 })}
                classes={{ root: classes.tablePagination }}
            />
            {displayHistoryModal && (
                <StockHistoryModal
                    clinic={currentLocation}
                    isVisible={displayHistoryModal}
                    setIsVisible={setDisplayHistoryModal}
                    product={productHistory}
                />
            )}
        </>
    );
}

StockAllList.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: PropTypes.object,
    filter: PropTypes.object,
    currentLocation: PropTypes.string.isRequired,
    setFilter: PropTypes.func,
    incTaxFilter: PropTypes.bool,
    setEditStockProduct: PropTypes.func,
    suppliersFilter: PropTypes.array,
    brandsFilter: PropTypes.array,
    categoriesFilter: PropTypes.array
};

export default withStyles(styles)(StockAllList);
