export const stockAllViewStyles = () => ({
    title: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500
    },
    marginRight8px: {
        marginRight: 8
    },
    modalEmail: {
        '& .MuiDialog-paper': {
            width: '40%',
            height: '40%',
            padding: 32
        }
    },
    lowerGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    inputFilters: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    checkboxGroup: {
        '& > div': {
            '& span': {
                color: 'rgba(0, 0, 0, 0.87)'
            }
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'space-between',
        '& .MuiFormControlLabel-root': {
            marginLeft: '-4px',
            '& span': {
                padding: '2px'
            }
        },
        '& div:nth-child(2)': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    nextBtn: {
        background: '#B41678',
        textTransform: 'none',
        boxShadow: 'none',
        color: 'white',
        borderRadius: 0,
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        }
    },
    cancelBtn: {
        background: 'white',
        textTransform: 'none',
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    marginTop16px: {
        marginTop: 24
    }
});

export const stockAllListStyles = () => ({
    table: {
        marginTop: 16,
        '& .MuiTableCell-root': {
            padding: '0.5vw'
        }
    },
    tableHeader: {
        opacity: 1,
        fontWeight: 'bold',
        borderColor: 'black',
        borderWidth: 2
    },
    pointer: {
        cursor: 'pointer'
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        height: 50,
        cursor: 'default',
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        }
    },
    tableRowOrange: {
        backgroundColor: '#ffd7a3'
    },
    archivedRow: {
        backgroundColor: '#ff9e9e'
    },
    tableRowRed: {
        backgroundColor: '#ff6666'
    },
    marginRight48px: {
        marginRight: 48
    },
    paddingRight33px: {
        paddingRight: '33px !important'
    },
    alignTextRight: {
        textAlign: 'right !important'
    },
    firstTableRow: {
        '&> td': {
            '&> span': {
                fontWeight: 700
            }
        }
    },
    textEnd: {
        alignText: 'end'
    },
    backgroundWhite: {
        background: '#fafafa'
    },
    rowClickable: {
        cursor: 'pointer',
        '&> span': {
            color: 'blue'
        }
    },
    blueTextCell: {
        '&> span': {
            color: 'blue'
        }
    }
});

export const stockHistoryStyles = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 10%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        minHeight: 400,
        paddingBottom: '3vh',
        outline: 'none',
        '& h5': {
            margin: '3%'
        }
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    table: {
        width: '90%',
        '& thead tr': {
            '& th:nth-child(1)': {
                width: '15%'
            },
            '& th:nth-child(2)': {
                width: '15%'
            },
            '& th:nth-child(3), th:nth-child(4)': {
                width: '10%'
            },
            '& th:nth-child(5)': {
                width: '35%'
            }
        },
        '& tbody a': {
            color: 'blue',
            textDecoration: 'underline'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        }
    }
});

export const dropdownButtonStyles = () => ({
    optionsButton: {
        backgroundColor: '#3083EC',
        color: '#fff',
        width: 80,
        height: 38,
        borderRadius: 0,
        marginBottom: 16,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        padding: '6px 16px',

        '&:hover': {
            backgroundColor: '#3083EC'
        }
    }
});

export const EditStockStyles = () => ({
    formItem: {
        marginTop: 10
    },
    notes: {
        '& .MuiOutlinedInput-root': {
            height: '140px',
            maxHeight: 'unset',
            boxSizing: 'content-box'
        },
        '& input, textarea': {
            fontFamily: 'Roboto, Arial, "Sans-serif"',
            fontSize: '14px !important',
            boxSizing: 'border-box',
            height: 'inherit',
            '&::placeholder': {
                fontFamily: 'Roboto, Arial, "Sans-serif" !important',
                fontSize: '14px !important'
            }
        }
    },
    value: {
        '& input::placeholder': {
            fontFamily: 'Roboto, Arial, "Sans-serif"',
            fontSize: '14px !important'
        }
    },
    select: {
        '& input, .MuiSelect-root': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    shrinkingLabel: {
        '&.MuiInputLabel-shrink': {
            transform: 'translate(0, -1px) scale(0.75)'
        }
    }
});
