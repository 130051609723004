import React, { useState } from 'react';
import { Paper, Tabs, Tab, Box, makeStyles } from '@material-ui/core';
import StockAllView from './StockAllView';
import { subHeaderStyles } from '../../collums-constants/styles/stylesheets/headerStyles';
import ClinicSelector from '../../collums-components/components/common/ClinicSelector';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

function StockView() {
    const [tabValue, setTabValue] = useState(0);
    const [type, setType] = useState('All');
    const subHeaderClasses = makeStyles(subHeaderStyles)();

    const getCurrentClinic = () => {
        return localStorage.getItem(CURRENT_CLINIC);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOnChangeClinic = (clinic) => {
        localStorage.setItem(CURRENT_CLINIC, clinic.id);
        window.location.reload();
    };

    return (
        <>
            <Paper square>
                <Tabs
                    value={tabValue}
                    className={subHeaderClasses.subTabsContainer}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    TabIndicatorProps={{ className: subHeaderClasses.subTabIndicator }}
                >
                    <Tab label="All" value={0} onClick={() => setType('All')} className={subHeaderClasses.subTab} />
                    <Tab
                        label="Retail"
                        value={1}
                        onClick={() => setType('Retail')}
                        className={subHeaderClasses.subTab}
                    />
                    <Tab
                        label="Professional"
                        value={2}
                        onClick={() => setType('Professional')}
                        className={subHeaderClasses.subTab}
                    />
                    <Tab
                        label="Prescription"
                        value={3}
                        onClick={() => setType('Prescription')}
                        className={subHeaderClasses.subTab}
                    />
                </Tabs>
            </Paper>
            <div style={{ padding: '15px 35px 0' }}>
                <ClinicSelector clinicId={getCurrentClinic()} onChange={handleOnChangeClinic} />
            </div>
            <Box p={4}>
                <StockAllView type={type} />
            </Box>
        </>
    );
}

export default StockView;
