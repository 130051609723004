import { Dialog, DialogTitle, Box, DialogContent, Button, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import OrderApi from '../../api/orderApi';
import CloseIcon from '@material-ui/icons/Close';
import { ORDER } from '../../collums-constants/index';
import { toastr } from 'react-redux-toastr';

const confirmStyle = {
    backgroundColor: '#b41778',
    borderRadius: 0,
    color: 'white',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#912d6b'
    },
    padding: 10,
    fontSize: 12
};

const cancelButton = {
    borderColor: '#575A64',
    borderWidth: 1,
    borderStyle: 'solid',
    textTransform: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
    },
    padding: 10,
    fontSize: 12
};

const closeButton = {
    position: 'absolute',
    right: 5,
    top: 0,
    color: 'grey'
};

function OrdersModal({ order = {}, reloadData, type = '', onClose }) {
    const ArchiveOrder = () => {
        const archiveAction = async () => {
            try {
                await OrderApi.archive(order.id);
                toastr.success('Success', `Order successfuly ${order.archived ? 'unarchived' : 'archived'}!`);
            } catch (err) {
                toastr.error('Error', 'Something went wrong');
            }
            onClose();
            reloadData();
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Archive Order</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>
                        Are you sure you want to {order.archived ? 'unarchive' : 'archive'}?
                    </Typography>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button style={confirmStyle} onClick={archiveAction}>
                            {order.archived ? 'Unarchive' : 'Archive'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const DeleteOrder = () => {
        const deleteAction = async () => {
            try {
                await OrderApi.deleteOrder(order.id);
                toastr.success('Success', 'Order successfuly deleted!');
            } catch (err) {
                toastr.error('Error', 'Something went wrong');
            }
            onClose();
            reloadData();
        };

        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Delete Order</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>Are you sure you want to delete?</Typography>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button style={confirmStyle} onClick={deleteAction}>
                            Delete
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const UpdateOrderToAwaitDelivery = () => {
        const updateOrderAction = async () => {
            try {
                await OrderApi.updateOrderStatus(order.id, { status: ORDER.STATUS.AWAITING_DELIVERY });
                toastr.success('Success', 'Order successfuly updated!');
            } catch (err) {
                toastr.error('Error', 'Something went wrong');
            }
            onClose();
            reloadData();
        };
        return (
            <Dialog open fullWidth maxWidth="xs">
                <IconButton aria-label="close" style={closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Box alignItems="center" width="100%" display="flex" flexDirection="column">
                        <b style={{ fontSize: 16 }}>Update Order Status</b>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center' }}>Update order status to awaiting delivery.</Typography>
                    <Typography style={{ textAlign: 'center' }}>
                        This cannot be undone and your oder cannot be changed.
                    </Typography>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                        <Button style={cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button style={confirmStyle} onClick={updateOrderAction}>
                            Update
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    switch (type) {
        case 'delete':
            return <DeleteOrder />;
        case 'archive':
            return <ArchiveOrder />;
        case 'update':
            return <UpdateOrderToAwaitDelivery />;
        default:
            return <></>;
    }
}

OrdersModal.propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.object,
    reloadData: PropTypes.func.isRequired
};

export default OrdersModal;
