import { useRef, useEffect, useState } from 'react';

export default function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const isFirstRender = useRef(true);

    useEffect(
        () => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            } else {
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);

                return () => {
                    clearTimeout(handler);
                };
            }
        },
        // eslint-disable-next-line
        [value]
    );

    return debouncedValue;
}
